import React, { useState, useEffect, useRef } from 'react';
import { setHeaders, url } from '../../api';
import { useImageCache } from '../../context/ImageCacheContext';
import { fetchIcon } from 'src/utils/icons';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './CoffeeCardWeb.css';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/types';
import axios from 'axios';
import { toast } from 'react-toastify';
import BasicButton from '../basics/BasicButton';

// import coffee type icons
import frappeSketo from "../coffee_card/assets/coffees/frappe-sketo.svg";
import frappeGala from "../coffee_card/assets/coffees/frappe-gala.svg";
import espresso from "../coffee_card/assets/coffees/espresso.svg";
import freddoEsp from "../coffee_card/assets/coffees/freddo-esp.svg";
import freddoCap from "../coffee_card/assets/coffees/freddo-cap.svg";
import greek from "../coffee_card/assets/coffees/greek-coffee.svg";
import filter from "../coffee_card/assets/coffees/filter.svg";
import latte from "../coffee_card/assets/coffees/latte.svg";
import icedLatte from "../coffee_card/assets/coffees/iced-latte.svg";
import cappuccino from "../coffee_card/assets/coffees/cappuccino.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronUp,
    faMapMarkerAlt,
    faEye,
    faTrash,
    faCubes as faSugar,
    faDroplet as faMilk,
    faFilter as faBrewType,
    faCube,
    faPencilAlt,
    faSave,
    faTimes,
    faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';

// define proper types for coffee format definitions
interface RateCategory {
    weight: number;
    required: boolean;
}

interface SugarOptions {
    level: string[];
    type: string[];
}

interface MilkOptions {
    type: string[];
}

interface IceCreamOptions {
    type: string[];
}

interface CoffeeFormatDefinition {
    'full-name': string;
    'coffee-format': {
        sugar?: SugarOptions;
        milk?: MilkOptions;
        'ice-cream'?: IceCreamOptions;
        type?: string[];
    };
    'rate-categories': {
        [key: string]: RateCategory;
    };
}

type CoffeeFormats = {
    [key: string]: CoffeeFormatDefinition;
};

// Type for coffee format structure in the DB
type CoffeeFormatDefinitions = Record<string, {
    'full-name': string;
    'coffee-format': Record<string, any>;
    'rate-categories': Record<string, {
        weight: number;
        required: boolean;
    }>;
}>;

// coffee format definitions - used for editing options
export const COFFEE_FORMAT_DEFINITIONS: CoffeeFormatDefinitions = {
    'freddo-espresso': {
        'full-name': 'Freddo Espresso',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            }
        },
        'rate-categories': {
            'body': { 'weight': 10, 'required': true },
            'flavour': { 'weight': 7, 'required': true },
            'acidity': { 'weight': 6, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'look': { 'weight': 4, 'required': true },
            'sugar-quantity': { 'weight': 8, 'required': false },
            'sugar-dissolution': { 'weight': 5, 'required': false }
        }
    },
    'freddo-cappuccino': {
        'full-name': 'Freddo Cappuccino',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            },
            'milk': {
                'type': ['plant', 'cow', 'coconut', 'almond', 'oat']
            }
        },
        'rate-categories': {
            'body': { 'weight': 10, 'required': true },
            'flavour': { 'weight': 7, 'required': true },
            'acidity': { 'weight': 6, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'milk-consistency': { 'weight': 8, 'required': true },
            'milk-smoothness': { 'weight': 6, 'required': true },
            'milk-quality': { 'weight': 6, 'required': true },
            'look': { 'weight': 4, 'required': true },
            'sugar-quantity': { 'weight': 8, 'required': false },
            'sugar-dissolution': { 'weight': 5, 'required': false }
        }
    },
    'frappe': {
        'full-name': 'Frappe',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            },
            'milk': {
                'type': ['no-milk', 'evaporated', 'cow']
            }
        },
        'rate-categories': {
            'body': { 'weight': 10, 'required': true },
            'flavour': { 'weight': 7, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'milk-consistency': { 'weight': 8, 'required': false },
            'milk-quality': { 'weight': 6, 'required': false },
            'milk-flavour': { 'weight': 6, 'required': false },
            'look': { 'weight': 4, 'required': true },
            'sugar-quantity': { 'weight': 8, 'required': false },
            'sugar-dissolution': { 'weight': 8, 'required': false },
            'ice-quantity': { 'weight': 3, 'required': true },
            'foam': { 'weight': 7, 'required': true },
            'size': { 'weight': 4, 'required': false }
        }
    },
    'greek-coffee': {
        'full-name': 'Greek Coffee',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            }
        },
        'rate-categories': {
            'body': { 'weight': 10, 'required': true },
            'crema': { 'weight': 6, 'required': true },
            'flavour': { 'weight': 7, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'sugar-quantity': { 'weight': 8, 'required': false }
        }
    },
    'espresso': {
        'full-name': 'Espresso',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            }
        },
        'rate-categories': {
            'body': { 'weight': 10, 'required': true },
            'flavour': { 'weight': 7, 'required': true },
            'acidity': { 'weight': 6, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'crema': { 'weight': 8, 'required': true },
            'intensity': { 'weight': 8, 'required': true },
            'look': { 'weight': 8, 'required': true }
        }
    },
    'cappuccino': {
        'full-name': 'Cappuccino',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            },
            'milk': {
                'type': ['no-milk', 'evaporated', 'plant', 'cow', 'coconut', 'almond', 'oat']
            }
        },
        'rate-categories': {
            'body': { 'weight': 10, 'required': true },
            'flavour': { 'weight': 7, 'required': true },
            'acidity': { 'weight': 6, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'milk-consistency': { 'weight': 8, 'required': true },
            'milk-smoothness': { 'weight': 6, 'required': true },
            'milk-quality': { 'weight': 6, 'required': true },
            'look': { 'weight': 4, 'required': true },
            'sugar-quantity': { 'weight': 8, 'required': false }
        }
    },
    'filter': {
        'full-name': 'Filter Coffee',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['drip', 'pour-over', 'french-press', 'aeropress', 'chemex', 'siphon', 'cold-brew'],
                'milk': {
                    'type': ['no-milk', 'evaporated', 'plant', 'cow', 'coconut', 'almond', 'oat']
                }
            },
            'type': ['drip', 'pour-over', 'french-press', 'aeropress', 'chemex', 'siphon', 'cold-brew'],
            'milk': {
                'type': ['no-milk', 'evaporated', 'plant', 'cow', 'coconut', 'almond', 'oat']
            }
        },
        'rate-categories': {
            'body': { 'weight': 10, 'required': true },
            'flavour': { 'weight': 7, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'milk-quality': { 'weight': 6, 'required': false },
            'sugar-quantity': { 'weight': 8, 'required': false }
        }
    },
    'latte': {
        'full-name': 'Latte',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            },
            'milk': {
                'type': ['no-milk', 'evaporated', 'plant', 'cow', 'coconut', 'almond', 'oat']
            }
        },
        'rate-categories': {
            'body': { 'weight': 10, 'required': true },
            'flavour': { 'weight': 7, 'required': true },
            'acidity': { 'weight': 6, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'milk-consistency': { 'weight': 8, 'required': true },
            'milk-smoothness': { 'weight': 6, 'required': true },
            'milk-quality': { 'weight': 6, 'required': true },
            'look': { 'weight': 4, 'required': true },
            'sugar-quantity': { 'weight': 8, 'required': false }
        }
    },
    'iced-latte': {
        'full-name': 'Iced Latte',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            },
            'milk': {
                'type': ['no-milk', 'evaporated', 'plant', 'cow', 'coconut', 'almond', 'oat']
            }
        },
        'rate-categories': {
            'body': { 'weight': 10, 'required': true },
            'flavour': { 'weight': 7, 'required': true },
            'acidity': { 'weight': 6, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'milk-consistency': { 'weight': 8, 'required': true },
            'milk-quality': { 'weight': 6, 'required': true },
            'look': { 'weight': 4, 'required': true },
            'sugar-quantity': { 'weight': 8, 'required': false }
        }
    },
    'lungo': {
        'full-name': 'Lungo',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            }
        },
        'rate-categories': {
            'body': { 'weight': 8, 'required': true },
            'flavour': { 'weight': 7, 'required': true },
            'acidity': { 'weight': 8, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'crema': { 'weight': 5, 'required': true },
            'intensity': { 'weight': 6, 'required': true },
            'look': { 'weight': 4, 'required': true },
            'sugar-quantity': { 'weight': 5, 'required': false },
            'sugar-dissolution': { 'weight': 4, 'required': false }
        }
    },
    'americano': {
        'full-name': 'Americano',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            },
            'milk': {
                'type': ['no-milk', 'plant', 'cow', 'coconut', 'almond', 'oat']
            }
        },
        'rate-categories': {
            'body': { 'weight': 7, 'required': true },
            'flavour': { 'weight': 8, 'required': true },
            'acidity': { 'weight': 7, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'dilution': { 'weight': 9, 'required': true },
            'temperature': { 'weight': 5, 'required': true },
            'look': { 'weight': 4, 'required': true },
            'sugar-quantity': { 'weight': 5, 'required': false },
            'milk-quality': { 'weight': 6, 'required': false }
        }
    },
    'mocha': {
        'full-name': 'Mocha',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            },
            'milk': {
                'type': ['plant', 'cow', 'coconut', 'almond', 'oat']
            }
        },
        'rate-categories': {
            'body': { 'weight': 8, 'required': true },
            'flavour': { 'weight': 9, 'required': true },
            'acidity': { 'weight': 5, 'required': true },
            'aftertaste': { 'weight': 7, 'required': true },
            'milk-consistency': { 'weight': 7, 'required': true },
            'milk-smoothness': { 'weight': 6, 'required': true },
            'chocolate-integration': { 'weight': 9, 'required': true },
            'sweetness': { 'weight': 8, 'required': true },
            'look': { 'weight': 5, 'required': true },
            'sugar-quantity': { 'weight': 6, 'required': false }
        }
    },
    'flat-white': {
        'full-name': 'Flat White',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            },
            'milk': {
                'type': ['plant', 'cow', 'coconut', 'almond', 'oat']
            }
        },
        'rate-categories': {
            'body': { 'weight': 8, 'required': true },
            'flavour': { 'weight': 7, 'required': true },
            'acidity': { 'weight': 6, 'required': true },
            'aftertaste': { 'weight': 6, 'required': true },
            'milk-microfoam': { 'weight': 10, 'required': true },
            'milk-smoothness': { 'weight': 8, 'required': true },
            'milk-coffee-ratio': { 'weight': 9, 'required': true },
            'look': { 'weight': 6, 'required': true },
            'sugar-quantity': { 'weight': 5, 'required': false }
        }
    },
    'macchiato': {
        'full-name': 'Macchiato',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            },
            'milk': {
                'type': ['plant', 'cow', 'coconut', 'almond', 'oat']
            }
        },
        'rate-categories': {
            'body': { 'weight': 9, 'required': true },
            'flavour': { 'weight': 8, 'required': true },
            'acidity': { 'weight': 7, 'required': true },
            'aftertaste': { 'weight': 7, 'required': true },
            'milk-quantity': { 'weight': 9, 'required': true },
            'milk-foam': { 'weight': 8, 'required': true },
            'crema': { 'weight': 7, 'required': true },
            'look': { 'weight': 6, 'required': true },
            'sugar-quantity': { 'weight': 5, 'required': false }
        }
    },
    'affogato': {
        'full-name': 'Affogato',
        'coffee-format': {
            'sugar': {
                'level': ['no-sugar', 'a-little', 'medium', 'a-lot'],
                'type': ['white', 'brown']
            },
            'ice-cream': {
                'type': ['vanilla', 'chocolate', 'hazelnut', 'coffee']
            }
        },
        'rate-categories': {
            'body': { 'weight': 7, 'required': true },
            'flavour': { 'weight': 9, 'required': true },
            'acidity': { 'weight': 5, 'required': true },
            'aftertaste': { 'weight': 8, 'required': true },
            'ice-cream-quality': { 'weight': 10, 'required': true },
            'temperature-contrast': { 'weight': 9, 'required': true },
            'coffee-ice-cream-ratio': { 'weight': 8, 'required': true },
            'look': { 'weight': 7, 'required': true },
            'sugar-quantity': { 'weight': 4, 'required': false }
        }
    }
};

// mapping of coffee types to their corresponding SVG images
const coffeeTypeToImageMap: { [key: string]: string } = {
    // hot coffees
    'espresso': espresso,
    'cappuccino': cappuccino,
    'filter': filter,
    'latte': latte,

    // cold coffees
    'iced-latte': icedLatte,
    'freddo-esp': freddoEsp,
    'freddo-cap': freddoCap,

    // greek style coffees
    'greek': greek,
    'frappe-sketo': frappeSketo,
    'frappe': frappeGala,
    'frappe-gala': frappeGala,

    // new coffee types mapped to existing icons
    'americano': filter,       // americano shares filter icon
    'lungo': espresso,         // lungo shares espresso icon
    'macchiato': espresso,     // macchiato shares espresso icon
    'flat-white': latte,  // flat-white shares cappuccino icon
    'mocha': filter,            // mocha shares latte icon
    'affogato': freddoEsp,     // affogato shares freddo espresso icon (cold dessert coffee)
    // default is espresso
};

// helper function to format coffee type for display
const formatCoffeeType = (type: string): string => {
    // handle special cases
    const lowerType = type.toLowerCase();

    // map of special formatting cases
    const specialFormats: { [key: string]: string } = {
        'frappe-sketo': 'Frappe',
        'freddo-espresso': 'Freddo Espresso',
        'freddo-cappuccino': 'Freddo Cappuccino',
        'iced-latte': 'Iced Latte',
        'flat-white': 'Flat White',
        'greek-coffee': 'Greek Coffee'
    };

    // return special format if it exists
    if (specialFormats[lowerType]) {
        return specialFormats[lowerType];
    }

    // default formatting: capitalize each word
    return type
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

// helper function to normalize coffee type strings
const normalizeCoffeeType = (type: string, format?: { milk?: { type?: string }; sugar?: { level?: string } }): string => {
    let normalizedType = type.toLowerCase()
        .replace(/\s+/g, '-')  // replace spaces with hyphens
        .replace(/[^a-z0-9-]/g, ''); // remove special characters

    // special handling for frappe variations
    if (normalizedType.startsWith('frappe')) {
        if (normalizedType === 'frappe-sketo' || (format?.milk?.type === 'no-milk' && format?.sugar?.level === 'no-sugar')) {
            return 'frappe-sketo';
        } else if (format?.milk?.type && format.milk.type !== 'no-milk') {
            return 'frappe-gala';
        }
        return 'frappe';
    }

    // handle freddo variations
    if (normalizedType === 'freddoespresso') return 'freddo-espresso';
    if (normalizedType === 'freddocappuccino') return 'freddo-cappuccino';

    // handle other possible variations in input
    const coffeeTypeMap: { [key: string]: string } = {
        'filter-coffee': 'filter',
        'greekcoffe': 'greek-coffee',
        'greekcoffee': 'greek-coffee',
        'flatwhite': 'flat-white',
        'icedlatte': 'iced-latte',
        // add any other variations that might be entered differently
    };

    return coffeeTypeMap[normalizedType] || normalizedType;
};

interface Coordinates {
    lat: number;
    lng: number;
}

interface Location {
    storeCoordinates: Coordinates;
    measurementCoordinates: Coordinates;
    city: string;
    country: string;
}

interface Tweet {
    tweetId: string;
    tweetUrl: string;
    datePosted: Date;
    tweetReplys?: Tweet[];
}

interface CoffeeRateInterface {
    _id: string;
    location: {
        storeCoordinates: { lat: number; lng: number };
        measurementCoordinates: { lat: number; lng: number };
        city: string;
        country: string;
    };
    coffeeType: string;
    coffeeShop: string;
    folderPath: string;
    imgName: string;
    overallRating: number;
    rating: { [key: string]: string };
    format: { [key: string]: string };
    rateColor: string;
    tweet?: Tweet;
    isSelfMade?: boolean;
    date?: string;
    updatedAt?: string;
}

interface CoffeeCardWebProps {
    coffeeData: CoffeeRateInterface;
    onDelete?: () => void;
    showDeleteButtons?: boolean;
}

const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY || 'default_api_key';

const hexToRgb = (hex: string = '#666666') => {
    if (!hex) return '102, 102, 102'; // default gray if hex is undefined
    hex = hex.replace(/^#/, '');
    if (hex.length === 3) {
        hex = hex.split('').map(char => char + char).join('');
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `${r}, ${g}, ${b}`;
};

const interpolateColor = (factor: number, startColor: string = '#e54343', middleColor: string = '#ebc42c', endColor: string = '#2bb941'): string => {
    const hexToRgb = (hex: string) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    };

    const rgbToHex = (r: number, g: number, b: number) => {
        const componentToHex = (c: number) => {
            const hex = c.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
        };
        return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
    };

    let startRgb: number[], endRgb: number[];
    if (factor < 0.5) {
        startRgb = hexToRgb(startColor);
        endRgb = hexToRgb(middleColor);
        factor *= 2;
    } else {
        startRgb = hexToRgb(middleColor);
        endRgb = hexToRgb(endColor);
        factor = (factor - 0.5) * 2;
    }

    const resultRgb = startRgb.map((start, index) => {
        return Math.round(start + factor * (endRgb[index] - start));
    });

    return rgbToHex(resultRgb[0], resultRgb[1], resultRgb[2]);
};

// helper function to get sugar color based on level and type
const getSugarColor = (level: string, type?: string): string => {
    if (type === 'brown') return '#c7875a';  // brown sugar
    return '#edeeee';  // gray for white sugar
};

// helper function to get milk color based on type
const getMilkColor = (type: string): string => {
    switch (type) {
        case 'no-milk': return '#d1d5db';
        case 'plant': return '#d5f1df';
        case 'cow': return '#f8fafc';
        case 'coconut': return '#fef3c7';
        case 'almond': return '#fde68a';
        case 'oat': return '#d4a373';
        case 'evaporated': return '#fef9c3';
        default: return '#f8fafc';
    }
};

interface FormatDisplayProps {
    format?: { [key: string]: string } | null;
}

const FormatDisplay: React.FC<FormatDisplayProps> = ({ format }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    if (!format) return null;

    const formatDetails = Object.entries(format)
        .filter(([key, value]) => value !== undefined && value !== null)
        .map(([key, value]) => {
            const label = key.replace(/-/g, ' ');
            return `${label}: ${value}`;
        })
        .join('\n');

    return (
        <div className='format-display'>
            {format['brew-type'] && (
                <div
                    className='format-icon'
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <FontAwesomeIcon
                        icon={faBrewType}
                        style={{ color: '#4a5568' }}
                    />
                    <span className='format-value'>{format['brew-type']}</span>
                </div>
            )}
            {format['sugar-level'] && (
                <div
                    className='format-icon'
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    {format['sugar-level'] !== 'no-sugar' && (
                        format['sugar-level'] === 'a-little' ? (
                            <FontAwesomeIcon
                                icon={faCube}
                                className="sugar-icon"
                                style={{ color: getSugarColor(format['sugar-level'], format['sugar-type']) }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faSugar}
                                className="sugar-icon"
                                style={{ color: getSugarColor(format['sugar-level'], format['sugar-type']) }}
                            />
                        )
                    )}
                    <span className='format-value'>{format['sugar-level']}</span>
                </div>
            )}
            {format['milk-type'] && format['milk-type'] !== 'no-milk' && (
                <div
                    className='format-icon'
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <FontAwesomeIcon
                        icon={faMilk}
                        className="milk-icon"
                        style={{ color: getMilkColor(format['milk-type']) }}
                    />
                    <span className='format-value'>{format['milk-type']}</span>
                </div>
            )}
            {showTooltip && formatDetails && (
                <div className='format-tooltip'>
                    {formatDetails.split('\n').map((detail, index) => {
                        const [label, value] = detail.split(': ');
                        return (
                            <div key={index} className='tooltip-row'>
                                <span className='tooltip-label'>{label}:</span>
                                <span className='tooltip-value'>{value}</span>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

// Define a more specific type for the edit data
interface EditCoffeeData {
    coffeeType: string;
    coffeeShop: string;
    overallRating: number;
    rating: Record<string, string>;
    format: Record<string, any>;
    date: string;
    location: {
        city: string;
        country: string;
        storeCoordinates: {
            lat: number;
            lng: number;
        };
        measurementCoordinates: {
            lat: number;
            lng: number;
        };
    };
}

// Add a function to update the rating fields when the coffee type changes
const updateRatingFields = (coffeeType: string, currentRatings: Record<string, string> = {}): Record<string, string> => {
    if (!coffeeType || !COFFEE_FORMAT_DEFINITIONS[coffeeType]) {
        return currentRatings;
    }

    const rateCategories = COFFEE_FORMAT_DEFINITIONS[coffeeType]['rate-categories'];
    const updatedRatings: Record<string, string> = {};

    // Add all required categories with default values if not already present
    Object.entries(rateCategories).forEach(([category, details]) => {
        // Preserve existing ratings or set default to 5.0 for new categories
        updatedRatings[category] = currentRatings[category] || '5.0';
    });

    return updatedRatings;
};

const CoffeeCardWeb = ({ coffeeData, onDelete, showDeleteButtons = false }: CoffeeCardWebProps) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [showStats, setShowStats] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [fetchFailed, setFetchFailed] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editData, setEditData] = useState<EditCoffeeData>(() => ({
        coffeeType: '',
        coffeeShop: '',
        overallRating: 0,
        rating: {},
        format: {},
        date: '',
        location: {
            city: '',
            country: '',
            storeCoordinates: { lat: 0, lng: 0 },
            measurementCoordinates: { lat: 0, lng: 0 }
        }
    }));
    const [isSubmitting, setIsSubmitting] = useState(false);
    const cardRef = useRef<HTMLDivElement>(null);
    const user = useSelector((state: RootState) => state.auth);
    const { getImage, setImage, isLoading, setLoading } = useImageCache();

    // get the appropriate icon for the coffee type
    const getCoffeeIcon = () => {
        const normalizedType = normalizeCoffeeType(coffeeData.coffeeType, coffeeData.format);
        return coffeeTypeToImageMap[normalizedType] || coffeeTypeToImageMap['espresso'];
    };

    // Set up intersection observer for lazy loading
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold: 0.1,
                // Add a large root margin to start loading images well before they come into view
                rootMargin: '500px 0px'
            }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    // Only fetch image when card is visible
    useEffect(() => {
        if (!isVisible || !coffeeData._id || fetchFailed) {
            return;
        }

        // If we already have the image cached, mark it as loaded
        const cachedImage = getImage(coffeeData._id);
        if (cachedImage) {
            setImageLoaded(true);
            return;
        }

        // If we're already loading this image, don't start another request
        if (isLoading(coffeeData._id)) {
            return;
        }

        const fetchImage = async () => {
            try {
                setLoading(coffeeData._id, true);

                const response = await fetch(`${url}/api/coffee-rate/coffee/${coffeeData._id}/image`);
                if (!response.ok) {
                    console.error('Failed to fetch image URL');
                    setFetchFailed(true);
                    return;
                }

                const data = await response.json();
                if (!data.imageUrl) {
                    console.error('No image URL in response');
                    setFetchFailed(true);
                    return;
                }

                setImage(coffeeData._id, data.imageUrl);
            } catch (error) {
                console.error('Error fetching image:', error);
                setFetchFailed(true);
            } finally {
                setLoading(coffeeData._id, false);
            }
        };

        fetchImage();
    }, [coffeeData._id, getImage, setImage, isLoading, setLoading, isVisible, fetchFailed]);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const toggleStats = () => {
        setShowStats(!showStats);
    };

    const toggleMap = () => {
        setShowMap(!showMap);
        if (showStats) setShowStats(false);
    };

    const handleTweetClick = () => {
        if (coffeeData.tweet?.tweetUrl) {
            window.open(coffeeData.tweet.tweetUrl, '_blank');
        }
    };

    const handleDelete = async () => {
        if (!window.confirm('Are you sure you want to delete this coffee entry?')) {
            return;
        }

        try {
            const headers = setHeaders();
            const response = await axios.delete(`${url}/api/coffee-rate/coffee/${coffeeData._id}`, headers
            );

            if (response.status === 200) {
                toast.success('Coffee entry deleted successfully');
                onDelete?.();
            }
        } catch (error) {
            console.error('Error deleting coffee:', error);
            toast.error('Failed to delete coffee entry');
        }
    };

    const toggleEditMode = () => {
        if (!editMode) {
            // parse the format data to ensure proper nesting
            const parsedFormat: Record<string, any> = {};

            // process existing format data with the structure expected by the form
            if (coffeeData.format) {
                Object.entries(coffeeData.format).forEach(([key, value]) => {
                    if (key === 'sugar-level') {
                        if (!parsedFormat.sugar) parsedFormat.sugar = {};
                        parsedFormat.sugar.level = value;
                    } else if (key === 'sugar-type') {
                        if (!parsedFormat.sugar) parsedFormat.sugar = {};
                        parsedFormat.sugar.type = value;
                    } else if (key === 'milk-type') {
                        if (!parsedFormat.milk) parsedFormat.milk = {};
                        parsedFormat.milk.type = value;
                    } else if (key === 'ice-cream-type') {
                        if (!parsedFormat['ice-cream']) parsedFormat['ice-cream'] = {};
                        parsedFormat['ice-cream'].type = value;
                    } else {
                        // keep other format properties as is
                        parsedFormat[key] = value;
                    }
                });
            }

            setEditData({
                coffeeType: coffeeData.coffeeType,
                coffeeShop: coffeeData.coffeeShop,
                overallRating: coffeeData.overallRating,
                rating: { ...coffeeData.rating },
                format: parsedFormat,
                date: coffeeData.date || '',
                location: {
                    city: coffeeData.location.city,
                    country: coffeeData.location.country,
                    storeCoordinates: {
                        lat: coffeeData.location.storeCoordinates.lat,
                        lng: coffeeData.location.storeCoordinates.lng
                    },
                    measurementCoordinates: {
                        lat: coffeeData.location.measurementCoordinates.lat,
                        lng: coffeeData.location.measurementCoordinates.lng
                    }
                }
            });
        } else {
            // reset edit data when closing edit mode
            cancelEdit();
        }
        setEditMode(!editMode);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        if (name.includes('.')) {
            const [parent, child] = name.split('.');
            if (parent === 'location') {
                setEditData(prev => ({
                    ...prev,
                    location: {
                        ...prev.location,
                        [child]: value
                    }
                }));
            }
        } else {
            // Handle date-time specifically
            if (name === 'date' && value) {
                try {
                    // Ensure proper ISO format
                    const dateObj = new Date(value);
                    if (!isNaN(dateObj.getTime())) {
                        setEditData(prev => ({
                            ...prev,
                            [name]: dateObj.toISOString()
                        }));
                    }
                } catch (error) {
                    console.error('Invalid date format:', error);
                    setEditData(prev => ({
                        ...prev,
                        [name]: value
                    }));
                }
            } else {
                setEditData(prev => ({
                    ...prev,
                    [name]: value
                }));
            }
        }
    };

    const handleRatingChange = (category: string, value: string) => {
        setEditData(prev => ({
            ...prev,
            rating: {
                ...prev.rating,
                [category]: value
            }
        }));
    };

    const handleFormatChange = (key: string, value: string) => {
        // for simple format entries
        setEditData(prev => ({
            ...prev,
            format: {
                ...prev.format,
                [key]: value
            }
        }));
    };

    const handleCoordinateChange = (type: 'store' | 'measurement', coord: 'lat' | 'lng', value: string) => {
        const numValue = parseFloat(value);
        if (isNaN(numValue)) return;

        setEditData(prev => ({
            ...prev,
            location: {
                ...prev.location,
                [`${type}Coordinates`]: {
                    ...prev.location[`${type}Coordinates`],
                    [coord]: numValue
                }
            }
        }));
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        try {
            // flatten the format data for the API
            const flattenedFormat: Record<string, string> = {};

            // process nested format data back to flat structure
            if (editData.format) {
                Object.entries(editData.format).forEach(([key, value]) => {
                    if (key === 'sugar' && typeof value === 'object') {
                        if (value.level) flattenedFormat['sugar-level'] = value.level;
                        if (value.type) flattenedFormat['sugar-type'] = value.type;
                    } else if (key === 'milk' && typeof value === 'object') {
                        if (value.type) flattenedFormat['milk-type'] = value.type;
                    } else if (key === 'ice-cream' && typeof value === 'object') {
                        if (value.type) flattenedFormat['ice-cream-type'] = value.type;
                    } else {
                        // keep other format properties as is
                        flattenedFormat[key] = String(value);
                    }
                });
            }

            const updatedData = {
                coffeeType: editData.coffeeType,
                coffeeShop: editData.coffeeShop,
                overallRating: editData.overallRating,
                rating: editData.rating,
                format: flattenedFormat,
                date: editData.date,
                location: editData.location
            };

            const response = await axios.put(
                `${url}/api/coffee-rate/coffee/${coffeeData._id}`,
                updatedData,
                setHeaders()
            );

            if (response.status === 200) {
                // refresh the page to show updated data
                toast.success('Coffee details updated successfully!');
                window.location.reload();
            }
        } catch (error) {
            console.error('Error updating coffee details:', error);
            toast.error('Failed to update coffee details');
        } finally {
            setIsSubmitting(false);
        }
    };

    const cancelEdit = () => {
        setEditMode(false);
        // Reset edit data to initial state
        setEditData({
            coffeeType: '',
            coffeeShop: '',
            overallRating: 0,
            rating: {},
            format: {},
            date: '',
            location: {
                city: '',
                country: '',
                storeCoordinates: { lat: 0, lng: 0 },
                measurementCoordinates: { lat: 0, lng: 0 }
            }
        });
    };

    const openInGoogleMaps = () => {
        const { lat, lng } = coffeeData.location.storeCoordinates;
        const placeName = coffeeData.isSelfMade ? 'Home Made Coffee' : coffeeData.coffeeShop;
        const encodedPlaceName = encodeURIComponent(placeName);
        // search for the place name near coordinates with a zoom level of 17
        window.open(`https://www.google.com/maps/search/${encodedPlaceName}/@${lat},${lng},17z`, '_blank');
    };

    // Add these functions to support format editing
    const handleNestedFormatChange = (setEditData: React.Dispatch<React.SetStateAction<EditCoffeeData>>, category: string, subcategory: string, value: string) => {
        setEditData(prev => {
            const newFormat = { ...prev.format };

            if (!newFormat[category]) {
                newFormat[category] = {};
            }

            if (typeof newFormat[category] === 'object') {
                newFormat[category] = {
                    ...newFormat[category],
                    [subcategory]: value
                };
            }

            return {
                ...prev,
                format: newFormat
            };
        });
    };

    // Update ratings when coffee type changes
    const handleCoffeeTypeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const newCoffeeType = e.target.value;

        setEditData(prev => {
            // Update the coffee type
            const updatedData = {
                ...prev,
                coffeeType: newCoffeeType
            };

            // Update the rating fields based on the new coffee type
            updatedData.rating = updateRatingFields(newCoffeeType, prev.rating);

            return updatedData;
        });
    };

    return (
        <div className='coffee-card' ref={cardRef}>
            {showDeleteButtons && user.role === 'admin' && (
                <div className="admin-controls">
                    <div className="admin-icon delete-icon" onClick={handleDelete}>
                        <FontAwesomeIcon icon={faTrash} />
                    </div>
                    <div className="admin-icon" onClick={toggleEditMode}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                </div>
            )}
            <div className='card-content'>
                <div
                    className='header-info'
                    style={{
                        backgroundColor: `rgba(${hexToRgb(coffeeData.rateColor || '#666666')}, 0.1)`,
                        borderBottom: `2px solid rgba(${hexToRgb(coffeeData.rateColor || '#666666')}, 0.2)`
                    }}
                >
                    <div className='header-left'>
                        <div className='rating-web' style={{ color: coffeeData.rateColor || '#666666' }}>
                            {coffeeData.overallRating.toPrecision(2)}
                        </div>
                        <div className='type-container'>
                            <div className='type'>{formatCoffeeType(coffeeData.coffeeType)}</div>
                        </div>
                        {(!coffeeData.format || Object.keys(coffeeData.format).length === 0) ? (
                            <div className='format-display custom-tag'>
                                <div className='format-icon'>
                                    <FontAwesomeIcon
                                        icon={faSugar}
                                        className="sugar-icon"
                                        style={{ color: '#edeeee' }}
                                    />
                                    <span className='format-value'>testaki:)</span>
                                </div>
                            </div>
                        ) : (
                            <FormatDisplay format={coffeeData.format} />
                        )}
                    </div>

                    <div
                        className='header-right'
                        onClick={!coffeeData.isSelfMade ? openInGoogleMaps : undefined}
                        style={{ cursor: !coffeeData.isSelfMade ? 'pointer' : 'default' }}
                        title={!coffeeData.isSelfMade ? "Open in Google Maps" : ""}
                    >
                        <div className='shop-name'>{coffeeData.isSelfMade ? 'Home Made' : coffeeData.coffeeShop}</div>
                        {!coffeeData.isSelfMade && (
                            <div className='coordinates-web'>
                                {`${coffeeData.location.storeCoordinates.lat.toFixed(4)}, ${coffeeData.location.storeCoordinates.lng.toFixed(4)}`}
                                <FontAwesomeIcon icon={faExternalLinkAlt} className="external-link-icon" />
                            </div>
                        )}
                    </div>

                    <img
                        src={getCoffeeIcon()}
                        alt={formatCoffeeType(coffeeData.coffeeType)}
                        className="coffee-icon"
                    />
                </div>

                {!editMode ? (
                    <>
                        <div className='image-section'>
                            {showMap ? (
                                <LoadScript googleMapsApiKey={MAPS_API_KEY}>
                                    <GoogleMap
                                        mapContainerClassName="map-container"
                                        center={coffeeData.location.storeCoordinates}
                                        zoom={15}
                                    >
                                        <Marker position={coffeeData.location.storeCoordinates} />
                                    </GoogleMap>
                                </LoadScript>
                            ) : (
                                <>
                                    {(!imageLoaded || !getImage(coffeeData._id)) && (
                                        <div className="image-placeholder">
                                            <img
                                                src={getCoffeeIcon()}
                                                alt={fetchFailed ? "Failed to load image" : "Loading..."}
                                                style={fetchFailed ? { opacity: 0.5 } : undefined}
                                            />
                                            {fetchFailed && (
                                                <div className="fetch-error">Failed to load image</div>
                                            )}
                                        </div>
                                    )}
                                    {isVisible && getImage(coffeeData._id) && !fetchFailed && (
                                        <img
                                            src={getImage(coffeeData._id) || ''}
                                            alt={formatCoffeeType(coffeeData.coffeeType)}
                                            className={`coffee-image ${imageLoaded ? 'loaded' : ''}`}
                                            onLoad={handleImageLoad}
                                            onError={() => setFetchFailed(true)}
                                        />
                                    )}
                                </>
                            )}
                            {showStats && !showMap && (
                                <div className='detailed-ratings slide-up'>
                                    {coffeeData.rating && Object.keys(coffeeData.rating).length > 0 ? (
                                        Object.entries(coffeeData.rating).map(([key, value]) => {
                                            const ratingValue = parseFloat(value);
                                            const color = interpolateColor(ratingValue / 10);
                                            return (
                                                <div key={key} className='rating-item'>
                                                    <div>{key}</div>
                                                    <div style={{ color }}>{value}</div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div className='no-ratings'>No ratings available</div>
                                    )}
                                </div>
                            )}
                            <div className='button-container'>
                                <button className='toggle-button' onClick={toggleStats}>
                                    <FontAwesomeIcon icon={showStats ? faChevronUp : faChevronDown} />
                                    {showStats ? 'Hide Stats' : 'Show Stats'}
                                </button>
                                {!coffeeData.isSelfMade && (
                                    <button className='toggle-button' onClick={toggleMap}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                                        {showMap ? 'Hide Map' : 'Show Map'}
                                    </button>
                                )}
                            </div>
                        </div>

                        <div className='details-container'>
                            <div className='location-info'>
                                <div className='location'>
                                    {coffeeData.location.city}, {coffeeData.location.country}
                                </div>
                                <div className='details-row'>
                                    <div className='details-left'>
                                        {coffeeData.tweet && (
                                            <div className='tweet' onClick={handleTweetClick}>
                                                <FontAwesomeIcon icon={faXTwitter} className="twitter-icon" />
                                                View on X
                                            </div>
                                        )}
                                    </div>
                                    <div className='date'>
                                        {new Date(coffeeData.date || '').toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'short',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: false
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className='edit-container'>
                        <div className='edit-section'>
                            <h3>Edit Coffee Details</h3>

                            <div className='edit-group'>
                                <label>Coffee Type</label>
                                <select
                                    name="coffeeType"
                                    value={editData.coffeeType || ''}
                                    onChange={handleCoffeeTypeChange}
                                >
                                    <option value="">Select coffee type</option>
                                    {Object.keys(COFFEE_FORMAT_DEFINITIONS).map(type => (
                                        <option key={type} value={type}>
                                            {COFFEE_FORMAT_DEFINITIONS[type]['full-name']}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className='edit-group'>
                                <label>Coffee Shop</label>
                                <input
                                    type="text"
                                    name="coffeeShop"
                                    value={editData.coffeeShop || ''}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className='edit-group'>
                                <label>Date and Time</label>
                                <input
                                    type="datetime-local"
                                    name="date"
                                    value={editData.date ? new Date(editData.date).toISOString().slice(0, 16) : ''}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className='edit-group'>
                                <label>Location</label>
                                <div className='edit-subgroup'>
                                    <input
                                        type="text"
                                        name="location.city"
                                        placeholder="City"
                                        value={editData.location?.city || ''}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        type="text"
                                        name="location.country"
                                        placeholder="Country"
                                        value={editData.location?.country || ''}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>

                            <div className='edit-group'>
                                <label>Store Coordinates</label>
                                <div className='edit-subgroup'>
                                    <input
                                        type="number"
                                        placeholder="Latitude"
                                        value={editData.location?.storeCoordinates?.lat || ''}
                                        onChange={(e) => handleCoordinateChange('store', 'lat', e.target.value)}
                                        step="0.0001"
                                    />
                                    <input
                                        type="number"
                                        placeholder="Longitude"
                                        value={editData.location?.storeCoordinates?.lng || ''}
                                        onChange={(e) => handleCoordinateChange('store', 'lng', e.target.value)}
                                        step="0.0001"
                                    />
                                </div>
                            </div>

                            <div className='edit-group'>
                                <label>Measurement Coordinates</label>
                                <div className='edit-subgroup'>
                                    <input
                                        type="number"
                                        placeholder="Latitude"
                                        value={editData.location?.measurementCoordinates?.lat || ''}
                                        onChange={(e) => handleCoordinateChange('measurement', 'lat', e.target.value)}
                                        step="0.0001"
                                    />
                                    <input
                                        type="number"
                                        placeholder="Longitude"
                                        value={editData.location?.measurementCoordinates?.lng || ''}
                                        onChange={(e) => handleCoordinateChange('measurement', 'lng', e.target.value)}
                                        step="0.0001"
                                    />
                                </div>
                            </div>

                            <div className='edit-group'>
                                <label>Format</label>
                                {editData.coffeeType && COFFEE_FORMAT_DEFINITIONS[editData.coffeeType] ? (
                                    <div className="format-options">
                                        {Object.entries(COFFEE_FORMAT_DEFINITIONS[editData.coffeeType]['coffee-format']).map(([category, options]) => {
                                            // TypeScript can't infer the options type, so we need to handle each case explicitly
                                            if (category === 'sugar') {
                                                const sugarOptions = options as { level?: string[], type?: string[] };
                                                return (
                                                    <div key={category} className='edit-format-category'>
                                                        <label>Sugar</label>

                                                        {sugarOptions.level && (
                                                            <div className="edit-format-item">
                                                                <label>Sugar Level</label>
                                                                <select
                                                                    value={editData.format?.sugar?.level || ''}
                                                                    onChange={(e) => handleNestedFormatChange(setEditData, 'sugar', 'level', e.target.value)}
                                                                >
                                                                    <option value="">Select sugar level</option>
                                                                    {sugarOptions.level.map(level => (
                                                                        <option key={level} value={level}>
                                                                            {level.replace(/-/g, ' ')}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        )}

                                                        {sugarOptions.type && (
                                                            <div className="edit-format-item">
                                                                <label>Sugar Type</label>
                                                                <select
                                                                    value={editData.format?.sugar?.type || ''}
                                                                    onChange={(e) => handleNestedFormatChange(setEditData, 'sugar', 'type', e.target.value)}
                                                                >
                                                                    <option value="">Select sugar type</option>
                                                                    {sugarOptions.type.map(type => (
                                                                        <option key={type} value={type}>
                                                                            {type.replace(/-/g, ' ')}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }

                                            if (category === 'milk') {
                                                const milkOptions = options as { type?: string[] };
                                                return (
                                                    <div key={category} className='edit-format-category'>
                                                        <label>Milk</label>

                                                        {milkOptions.type && (
                                                            <div className="edit-format-item">
                                                                <label>Milk Type</label>
                                                                <select
                                                                    value={editData.format?.milk?.type || ''}
                                                                    onChange={(e) => handleNestedFormatChange(setEditData, 'milk', 'type', e.target.value)}
                                                                >
                                                                    <option value="">Select milk type</option>
                                                                    {milkOptions.type.map(type => (
                                                                        <option key={type} value={type}>
                                                                            {type.replace(/-/g, ' ')}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }

                                            if (category === 'ice-cream') {
                                                const iceCreamOptions = options as { type?: string[] };
                                                return (
                                                    <div key={category} className='edit-format-category'>
                                                        <label>Ice Cream</label>

                                                        {iceCreamOptions.type && (
                                                            <div className="edit-format-item">
                                                                <label>Ice Cream Type</label>
                                                                <select
                                                                    value={editData.format?.['ice-cream']?.type || ''}
                                                                    onChange={(e) => handleNestedFormatChange(setEditData, 'ice-cream', 'type', e.target.value)}
                                                                >
                                                                    <option value="">Select ice cream type</option>
                                                                    {iceCreamOptions.type.map(type => (
                                                                        <option key={type} value={type}>
                                                                            {type.replace(/-/g, ' ')}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }

                                            if (category === 'type' && Array.isArray(options)) {
                                                return (
                                                    <div key={category} className='edit-format-category'>
                                                        <label>Brew Method</label>

                                                        <div className="edit-format-item">
                                                            <select
                                                                value={editData.format?.type || ''}
                                                                onChange={(e) => handleFormatChange('type', e.target.value)}
                                                            >
                                                                <option value="">Select brew method</option>
                                                                {options.map(type => (
                                                                    <option key={type} value={type}>
                                                                        {type.replace(/-/g, ' ')}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            // Default case for unknown format types
                                            return (
                                                <div key={category} className='edit-format-category'>
                                                    <label>{category.replace(/-/g, ' ')}</label>
                                                    <div className="edit-format-item">
                                                        <input
                                                            type="text"
                                                            value={typeof editData.format[category] === 'string' ? editData.format[category] : ''}
                                                            onChange={(e) => handleFormatChange(category, e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    // Fallback for custom formats or unknown coffee types
                                    <div>
                                        {editData.format && Object.entries(editData.format).map(([key, value]) => {
                                            // Skip nested objects that are handled above
                                            if (typeof value === 'object') return null;

                                            return (
                                                <div key={key} className='edit-format-item'>
                                                    <label>{key.replace(/-/g, ' ')}</label>
                                                    <input
                                                        type="text"
                                                        value={value}
                                                        onChange={(e) => handleFormatChange(key, e.target.value)}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>

                            <div className='edit-group'>
                                <label>Ratings</label>
                                {editData.coffeeType && COFFEE_FORMAT_DEFINITIONS[editData.coffeeType] ? (
                                    <div className="rating-categories">
                                        {Object.entries(COFFEE_FORMAT_DEFINITIONS[editData.coffeeType]['rate-categories'])
                                            .sort(([, a], [, b]) => b.weight - a.weight) // Sort by weight (descending)
                                            .map(([category, details]) => (
                                                <div key={category} className={`edit-rating-item ${details.required ? 'required' : 'optional'}`}>
                                                    <label>
                                                        {category.replace(/-/g, ' ')}
                                                        {details.required && <span className="required-indicator">*</span>}
                                                        <span className="weight-indicator">(Weight: {details.weight})</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        value={editData.rating[category] || ''}
                                                        onChange={(e) => handleRatingChange(category, e.target.value)}
                                                        min="0"
                                                        max="10"
                                                        step="0.1"
                                                        required={details.required}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                ) : (
                                    // Fallback for custom ratings or unknown coffee types
                                    <div>
                                        {editData.rating && Object.entries(editData.rating).map(([category, value]) => (
                                            <div key={category} className='edit-rating-item'>
                                                <label>{category.replace(/-/g, ' ')}</label>
                                                <input
                                                    type="number"
                                                    value={value}
                                                    onChange={(e) => handleRatingChange(category, e.target.value)}
                                                    min="0"
                                                    max="10"
                                                    step="0.1"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div className='edit-actions'>
                                <button
                                    className='edit-button save'
                                    onClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    <FontAwesomeIcon icon={faSave} />
                                    {isSubmitting ? 'Saving...' : 'Save Changes'}
                                </button>
                                <button className='edit-button cancel' onClick={cancelEdit}>
                                    <FontAwesomeIcon icon={faTimes} />
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CoffeeCardWeb;