import { UserStatusState } from './types';

// action types
const FETCH_USER_STATUS_REQUEST = 'FETCH_USER_STATUS_REQUEST';
const FETCH_USER_STATUS_SUCCESS = 'FETCH_USER_STATUS_SUCCESS';
const FETCH_USER_STATUS_FAILURE = 'FETCH_USER_STATUS_FAILURE';
const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';

interface FetchUserStatusRequestAction {
    type: typeof FETCH_USER_STATUS_REQUEST;
}

interface FetchUserStatusSuccessAction {
    type: typeof FETCH_USER_STATUS_SUCCESS;
    payload: {
        emailVerified: boolean;
        birthdayToday: boolean;
        emojiSet: string | null;
        badges: number;
    };
}

interface FetchUserStatusFailureAction {
    type: typeof FETCH_USER_STATUS_FAILURE;
    payload: string;
}

interface UpdateUserStatusAction {
    type: typeof UPDATE_USER_STATUS;
    payload: Partial<UserStatusState>;
}

type UserStatusActionTypes =
    | FetchUserStatusRequestAction
    | FetchUserStatusSuccessAction
    | FetchUserStatusFailureAction
    | UpdateUserStatusAction;

// initial state
const initialState: UserStatusState = {
    emailVerified: false,
    birthdayToday: false,
    birthdaySet: false,
    emojiSet: null,
    badges: 0,
    loading: false,
    error: null
};

// reducer
const statusReducer = (
    state = initialState,
    action: UserStatusActionTypes
): UserStatusState => {
    switch (action.type) {
        case FETCH_USER_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_USER_STATUS_SUCCESS:
            return {
                ...state,
                ...action.payload,
                // determine if birthday is set
                birthdaySet: action.payload.birthdayToday !== undefined,
                loading: false,
                error: null
            };

        case FETCH_USER_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case UPDATE_USER_STATUS:
            return {
                ...state,
                ...action.payload
            };

        default:
            return state;
    }
};

export default statusReducer; 