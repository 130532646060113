import React, { useState, useRef, useEffect } from 'react';
import { setHeaders, url } from '../../api';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes,
    faCloudUploadAlt,
    faCheck,
    faImage,
    faSpinner,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';
import './AddCoffeeForm.css';
import axios from 'axios';

// import coffee format definitions from CoffeeCardWeb
import { COFFEE_FORMAT_DEFINITIONS } from './CoffeeCardWeb';

interface AddCoffeeFormProps {
    onClose: () => void;
    onSuccess?: () => void;
}

interface LocationData {
    city: string;
    country: string;
    storeCoordinates: {
        lat: number;
        lng: number;
    };
    measurementCoordinates: {
        lat: number;
        lng: number;
    };
}

// create form data interface
interface CoffeeFormData {
    coffeeType: string;
    coffeeShop: string;
    location: LocationData;
    format: Record<string, any>;
    rating: Record<string, string>;
}

// Define RateCategory interface to match the format in CoffeeCardWeb
interface RateCategory {
    weight: number;
    required: boolean;
}

const AddCoffeeForm: React.FC<AddCoffeeFormProps> = ({ onClose, onSuccess }) => {
    // form steps state
    const [currentStep, setCurrentStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [statusMessage, setStatusMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);

    // newly created coffee ID for image upload
    const [newCoffeeId, setNewCoffeeId] = useState<string | null>(null);
    const [folderPath, setFolderPath] = useState<string | null>(null);

    // image upload state
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    // form data state
    const [formData, setFormData] = useState<CoffeeFormData>({
        coffeeType: '',
        coffeeShop: '',
        location: {
            city: '',
            country: '',
            storeCoordinates: { lat: 0, lng: 0 },
            measurementCoordinates: { lat: 0, lng: 0 }
        },
        format: {},
        rating: {}
    });

    // Add new states for location fetching
    const [isGettingLocation, setIsGettingLocation] = useState(false);
    const [locationError, setLocationError] = useState<string | null>(null);

    // Generate required rating fields based on selected coffee type
    useEffect(() => {
        if (formData.coffeeType && COFFEE_FORMAT_DEFINITIONS[formData.coffeeType]) {
            const rateCategories = COFFEE_FORMAT_DEFINITIONS[formData.coffeeType]['rate-categories'];
            const initialRatings: Record<string, string> = {};

            Object.entries(rateCategories).forEach(([category, details]) => {
                // initialize rating with default value
                initialRatings[category] = '5.0';
            });

            setFormData(prev => ({
                ...prev,
                rating: initialRatings
            }));
        }
    }, [formData.coffeeType]);

    // Create preview URL for selected image
    useEffect(() => {
        if (selectedImage) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result as string);
            };
            reader.readAsDataURL(selectedImage);
        } else {
            setPreviewUrl(null);
        }
    }, [selectedImage]);

    // Handle input changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        // handle nested properties (location data)
        if (name.includes('.')) {
            const [parent, child] = name.split('.');

            if (parent === 'location') {
                setFormData(prev => ({
                    ...prev,
                    location: {
                        ...prev.location,
                        [child]: value
                    }
                }));
            }
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    // Handle coordinate changes
    const handleCoordinateChange = (type: 'store' | 'measurement', coord: 'lat' | 'lng', value: string) => {
        setFormData(prev => ({
            ...prev,
            location: {
                ...prev.location,
                [`${type}Coordinates`]: {
                    ...prev.location[`${type}Coordinates`],
                    [coord]: parseFloat(value) || 0
                }
            }
        }));
    };

    // Handle rating changes
    const handleRatingChange = (category: string, value: string) => {
        setFormData(prev => ({
            ...prev,
            rating: {
                ...prev.rating,
                [category]: value
            }
        }));
    };

    // Handle format changes
    const handleFormatChange = (key: string, value: string) => {
        setFormData(prev => ({
            ...prev,
            format: {
                ...prev.format,
                [key]: value
            }
        }));
    };

    // Handle nested format changes
    const handleNestedFormatChange = (category: string, subcategory: string, value: string) => {
        setFormData(prev => {
            const newFormat = { ...prev.format };

            if (!newFormat[category]) {
                newFormat[category] = {};
            }

            if (typeof newFormat[category] === 'object') {
                newFormat[category] = {
                    ...newFormat[category],
                    [subcategory]: value
                };
            }

            return {
                ...prev,
                format: newFormat
            };
        });
    };

    // Handle image selection
    const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setSelectedImage(e.dataTransfer.files[0]);
        }
    };

    // Function to get current location from GPS
    const getCurrentLocation = () => {
        if (!navigator.geolocation) {
            setLocationError("Geolocation is not supported by your browser");
            return;
        }

        setIsGettingLocation(true);
        setLocationError(null);

        navigator.geolocation.getCurrentPosition(
            (position) => {
                // success callback
                const { latitude, longitude } = position.coords;

                // update both store and measurement coordinates with the same values
                // user can adjust them later if needed
                setFormData(prev => ({
                    ...prev,
                    location: {
                        ...prev.location,
                        storeCoordinates: {
                            lat: latitude,
                            lng: longitude
                        },
                        measurementCoordinates: {
                            lat: latitude,
                            lng: longitude
                        }
                    }
                }));

                // Try to get city and country using reverse geocoding
                fetchLocationDetails(latitude, longitude);

                setIsGettingLocation(false);
            },
            (error) => {
                // error callback
                let errorMessage = "Unable to retrieve your location";
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        errorMessage = "Location access was denied. Please enable location permissions.";
                        break;
                    case error.POSITION_UNAVAILABLE:
                        errorMessage = "Location information is unavailable.";
                        break;
                    case error.TIMEOUT:
                        errorMessage = "The request to get location timed out.";
                        break;
                }
                setLocationError(errorMessage);
                setIsGettingLocation(false);
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            }
        );
    };

    // Function to get city and country from coordinates using a free geocoding service
    const fetchLocationDetails = async (latitude: number, longitude: number) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10`);
            const data = await response.json();

            if (data && data.address) {
                setFormData(prev => ({
                    ...prev,
                    location: {
                        ...prev.location,
                        city: data.address.city || data.address.town || data.address.village || '',
                        country: data.address.country || ''
                    }
                }));
            }
        } catch (error) {
            console.error("Error fetching location details:", error);
            // Don't show an error to the user - the coordinates are still set
        }
    };

    // Function to create a coffee entry
    const createCoffeeEntry = async () => {
        setIsSubmitting(true);
        setStatusMessage(null);

        try {
            // flatten the format data for the API
            const flattenedFormat: Record<string, string> = {};

            // process nested format data back to flat structure
            if (formData.format) {
                Object.entries(formData.format).forEach(([key, value]) => {
                    if (key === 'sugar' && typeof value === 'object') {
                        if (value.level) flattenedFormat['sugar-level'] = value.level;
                        if (value.type) flattenedFormat['sugar-type'] = value.type;
                    } else if (key === 'milk' && typeof value === 'object') {
                        if (value.type) flattenedFormat['milk-type'] = value.type;
                    } else if (key === 'ice-cream' && typeof value === 'object') {
                        if (value.type) flattenedFormat['ice-cream-type'] = value.type;
                    } else {
                        // keep other format properties as is
                        flattenedFormat[key] = String(value);
                    }
                });
            }

            const payload = {
                coffeeType: formData.coffeeType,
                coffeeShop: formData.coffeeShop,
                location: formData.location,
                format: flattenedFormat,
                rating: formData.rating
            };
            const headers = setHeaders();
            const response = await axios.post(
                `${url}/api/coffee-rate/coffee`,
                payload,
                headers
            );

            setNewCoffeeId(response.data.coffeeId);
            setFolderPath(response.data.folderPath);
            setStatusMessage({
                type: 'success',
                text: 'Coffee entry created successfully! Now upload an image.'
            });

            // proceed to image upload step
            setCurrentStep(2);
        } catch (error: any) {
            console.error('Error creating coffee entry:', error);
            setStatusMessage({
                type: 'error',
                text: error.response?.data?.message || 'An error occurred creating the coffee entry'
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    // Function to upload image for a coffee entry
    const uploadCoffeeImage = async () => {
        if (!selectedImage || !newCoffeeId) {
            setStatusMessage({
                type: 'error',
                text: 'Please select an image to upload'
            });
            return;
        }

        setIsSubmitting(true);
        setStatusMessage(null);

        try {
            const formData = new FormData();
            formData.append('image', selectedImage);

            const response = await axios.post(
                `${url}/api/coffee/${newCoffeeId}/image`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            setStatusMessage({
                type: 'success',
                text: 'Image uploaded successfully! Coffee entry complete.'
            });

            toast.success('New coffee entry created successfully!');

            // optional success callback
            if (onSuccess) {
                setTimeout(() => {
                    onSuccess();
                }, 1500);
            }
        } catch (error: any) {
            console.error('Error uploading image:', error);
            setStatusMessage({
                type: 'error',
                text: error.response?.data?.message || 'An error occurred uploading the image'
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (currentStep === 1) {
            await createCoffeeEntry();
        } else if (currentStep === 2) {
            await uploadCoffeeImage();
        }
    };

    // Check if form is valid
    const isFormValid = () => {
        const { coffeeType, coffeeShop, location } = formData;
        const locationValid = location.city && location.country &&
            location.storeCoordinates.lat && location.storeCoordinates.lng;

        if (currentStep === 1) {
            return coffeeType && coffeeShop && locationValid;
        } else if (currentStep === 2) {
            return !!selectedImage;
        }

        return false;
    };

    // Prevent background scrolling when modal is open
    React.useEffect(() => {
        // Disable body scrolling when component mounts
        document.body.style.overflow = 'hidden';

        // Re-enable body scrolling when component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    // Prevent clicks inside the modal from closing it
    const handleModalClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    return (
        <div className="modal-backdrop" onClick={onClose}>
            <div className="add-coffee-card" onClick={handleModalClick}>
                <div className="add-coffee-card-header">
                    <h2>Add New Coffee</h2>
                    <button className="close-button" onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>

                <div className="add-coffee-card-content">
                    {/* Steps indicator */}
                    <div className="step-indicator">
                        <div className="step">
                            <div className={`step-circle ${currentStep === 1 ? 'active' : currentStep > 1 ? 'completed' : ''}`}>
                                {currentStep > 1 ? <FontAwesomeIcon icon={faCheck} /> : 1}
                            </div>
                            <div className={`step-label ${currentStep === 1 ? 'active' : ''}`}>Details</div>
                        </div>
                        <div className="step">
                            <div className={`step-circle ${currentStep === 2 ? 'active' : currentStep > 2 ? 'completed' : ''}`}>
                                {currentStep > 2 ? <FontAwesomeIcon icon={faCheck} /> : 2}
                            </div>
                            <div className={`step-label ${currentStep === 2 ? 'active' : ''}`}>Image</div>
                        </div>
                    </div>

                    {/* Status messages */}
                    {statusMessage && (
                        <div className={`status-message ${statusMessage.type}`}>
                            {statusMessage.text}
                        </div>
                    )}

                    <form className="add-coffee-form" onSubmit={handleSubmit}>
                        {currentStep === 1 && (
                            <>
                                {/* Basic Details Section */}
                                <div className="form-section">
                                    <h3>Basic Information</h3>

                                    <div className="form-group">
                                        <label className="required-label">Coffee Type</label>
                                        <select
                                            name="coffeeType"
                                            value={formData.coffeeType}
                                            onChange={handleInputChange}
                                            required
                                        >
                                            <option value="">Select coffee type</option>
                                            {Object.keys(COFFEE_FORMAT_DEFINITIONS).map(type => (
                                                <option key={type} value={type}>
                                                    {COFFEE_FORMAT_DEFINITIONS[type]['full-name']}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <label className="required-label">Coffee Shop</label>
                                        <input
                                            type="text"
                                            name="coffeeShop"
                                            value={formData.coffeeShop}
                                            onChange={handleInputChange}
                                            placeholder="Name of the coffee shop"
                                            required
                                        />
                                    </div>
                                </div>

                                {/* Location Section */}
                                <div className="form-section">
                                    <h3>Location Information</h3>

                                    <div className="location-gps-container">
                                        <button
                                            type="button"
                                            className="get-location-button"
                                            onClick={getCurrentLocation}
                                            disabled={isGettingLocation}
                                        >
                                            <FontAwesomeIcon
                                                icon={isGettingLocation ? faSpinner : faMapMarkerAlt}
                                                spin={isGettingLocation}
                                                className="location-icon"
                                            />
                                            {isGettingLocation ? 'Getting Location...' : 'Get Current Location from GPS'}
                                        </button>
                                        {locationError && <div className="location-error">{locationError}</div>}
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group">
                                            <label className="required-label">City</label>
                                            <input
                                                type="text"
                                                name="location.city"
                                                value={formData.location.city}
                                                onChange={handleInputChange}
                                                placeholder="City"
                                                required
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label className="required-label">Country</label>
                                            <input
                                                type="text"
                                                name="location.country"
                                                value={formData.location.country}
                                                onChange={handleInputChange}
                                                placeholder="Country"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="required-label">Store Coordinates</label>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    value={formData.location.storeCoordinates.lat || ''}
                                                    onChange={(e) => handleCoordinateChange('store', 'lat', e.target.value)}
                                                    placeholder="Latitude"
                                                    step="0.0001"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    value={formData.location.storeCoordinates.lng || ''}
                                                    onChange={(e) => handleCoordinateChange('store', 'lng', e.target.value)}
                                                    placeholder="Longitude"
                                                    step="0.0001"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="required-label">Measurement Coordinates</label>
                                        <div className="form-row">
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    value={formData.location.measurementCoordinates.lat || ''}
                                                    onChange={(e) => handleCoordinateChange('measurement', 'lat', e.target.value)}
                                                    placeholder="Latitude"
                                                    step="0.0001"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    type="number"
                                                    value={formData.location.measurementCoordinates.lng || ''}
                                                    onChange={(e) => handleCoordinateChange('measurement', 'lng', e.target.value)}
                                                    placeholder="Longitude"
                                                    step="0.0001"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Format Options Section */}
                                {formData.coffeeType && COFFEE_FORMAT_DEFINITIONS[formData.coffeeType] && (
                                    <div className="form-section">
                                        <h3>Format Options</h3>
                                        <div className="format-options">
                                            {Object.entries(COFFEE_FORMAT_DEFINITIONS[formData.coffeeType]['coffee-format']).map(([category, options]) => {
                                                // Handle sugar options
                                                if (category === 'sugar') {
                                                    const sugarOptions = options as { level?: string[], type?: string[] };
                                                    return (
                                                        <div key={category} className='edit-format-category'>
                                                            <label>Sugar</label>

                                                            {sugarOptions.level && (
                                                                <div className="edit-format-item">
                                                                    <label>Sugar Level</label>
                                                                    <select
                                                                        value={formData.format?.sugar?.level || ''}
                                                                        onChange={(e) => handleNestedFormatChange('sugar', 'level', e.target.value)}
                                                                    >
                                                                        <option value="">Select sugar level</option>
                                                                        {sugarOptions.level.map(level => (
                                                                            <option key={level} value={level}>
                                                                                {level.replace(/-/g, ' ')}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}

                                                            {sugarOptions.type && (
                                                                <div className="edit-format-item">
                                                                    <label>Sugar Type</label>
                                                                    <select
                                                                        value={formData.format?.sugar?.type || ''}
                                                                        onChange={(e) => handleNestedFormatChange('sugar', 'type', e.target.value)}
                                                                    >
                                                                        <option value="">Select sugar type</option>
                                                                        {sugarOptions.type.map(type => (
                                                                            <option key={type} value={type}>
                                                                                {type.replace(/-/g, ' ')}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }

                                                // Handle milk options
                                                if (category === 'milk') {
                                                    const milkOptions = options as { type?: string[] };
                                                    return (
                                                        <div key={category} className='edit-format-category'>
                                                            <label>Milk</label>

                                                            {milkOptions.type && (
                                                                <div className="edit-format-item">
                                                                    <label>Milk Type</label>
                                                                    <select
                                                                        value={formData.format?.milk?.type || ''}
                                                                        onChange={(e) => handleNestedFormatChange('milk', 'type', e.target.value)}
                                                                    >
                                                                        <option value="">Select milk type</option>
                                                                        {milkOptions.type.map(type => (
                                                                            <option key={type} value={type}>
                                                                                {type.replace(/-/g, ' ')}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }

                                                // Handle ice cream options
                                                if (category === 'ice-cream') {
                                                    const iceCreamOptions = options as { type?: string[] };
                                                    return (
                                                        <div key={category} className='edit-format-category'>
                                                            <label>Ice Cream</label>

                                                            {iceCreamOptions.type && (
                                                                <div className="edit-format-item">
                                                                    <label>Ice Cream Type</label>
                                                                    <select
                                                                        value={formData.format?.['ice-cream']?.type || ''}
                                                                        onChange={(e) => handleNestedFormatChange('ice-cream', 'type', e.target.value)}
                                                                    >
                                                                        <option value="">Select ice cream type</option>
                                                                        {iceCreamOptions.type.map(type => (
                                                                            <option key={type} value={type}>
                                                                                {type.replace(/-/g, ' ')}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }

                                                // Handle brew method options
                                                if (category === 'type' && Array.isArray(options)) {
                                                    return (
                                                        <div key={category} className='edit-format-category'>
                                                            <label>Brew Method</label>

                                                            <div className="edit-format-item">
                                                                <select
                                                                    value={formData.format?.type || ''}
                                                                    onChange={(e) => handleFormatChange('type', e.target.value)}
                                                                >
                                                                    <option value="">Select brew method</option>
                                                                    {options.map(type => (
                                                                        <option key={type} value={type}>
                                                                            {type.replace(/-/g, ' ')}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    );
                                                }

                                                // Default case for unknown format types
                                                return null;
                                            })}
                                        </div>
                                    </div>
                                )}

                                {/* Rating Section */}
                                {formData.coffeeType && COFFEE_FORMAT_DEFINITIONS[formData.coffeeType] && (
                                    <div className="form-section">
                                        <h3>Ratings</h3>
                                        <div className="rating-categories">
                                            {Object.entries(COFFEE_FORMAT_DEFINITIONS[formData.coffeeType]['rate-categories'])
                                                .sort(([, a], [, b]) => (a as RateCategory).weight - (b as RateCategory).weight) // Sort by weight (descending)
                                                .map(([category, details]) => (
                                                    <div key={category} className={`edit-rating-item ${(details as RateCategory).required ? 'required' : 'optional'}`}>
                                                        <label>
                                                            {category.replace(/-/g, ' ')}
                                                            {(details as RateCategory).required && <span className="required-indicator">*</span>}
                                                            <span className="weight-indicator">(Weight: {(details as RateCategory).weight})</span>
                                                        </label>
                                                        <input
                                                            type="number"
                                                            value={formData.rating[category] || ''}
                                                            onChange={(e) => handleRatingChange(category, e.target.value)}
                                                            min="0"
                                                            max="10"
                                                            step="0.1"
                                                            required={(details as RateCategory).required}
                                                        />
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        {currentStep === 2 && (
                            <div className="form-section">
                                <h3>Upload Coffee Image</h3>
                                <p>Upload an image of your coffee for the entry {folderPath && `(${folderPath})`}</p>

                                <div
                                    className={`image-upload-section ${isDragging ? 'dragging' : ''}`}
                                    onDragOver={handleDragOver}
                                    onDragLeave={handleDragLeave}
                                    onDrop={handleDrop}
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleImageSelect}
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                    />

                                    {previewUrl ? (
                                        <div className="image-preview-container">
                                            <img src={previewUrl} alt="Selected coffee" className="image-preview" />
                                            <p>Click or drag to change image</p>
                                        </div>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faImage} style={{ fontSize: '3rem', color: '#aaa', marginBottom: '10px' }} />
                                            <p>Click here or drag and drop an image</p>
                                            <div className="image-upload-button">
                                                <FontAwesomeIcon icon={faCloudUploadAlt} style={{ marginRight: '8px' }} />
                                                Select Image
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}

                        <button
                            type="submit"
                            className="submit-button"
                            disabled={isSubmitting || !isFormValid()}
                        >
                            {isSubmitting && <span className="loading-spinner"></span>}
                            {currentStep === 1 ? 'Create Coffee Entry' : 'Upload Image & Complete'}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddCoffeeForm; 