import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState, UserStatusState } from '../reducers/types';
import { fetchUserStatus } from '../actions/statusActions';

export const useUserStatus = () => {
    const dispatch = useDispatch<ThunkDispatch<RootState, void, AnyAction>>();
    const statusState = useSelector((state: RootState) => state.status);

    const fetchStatus = () => {
        dispatch(fetchUserStatus());
    };

    useEffect(() => {
        // fetch status when the hook is first used if not already loaded
        if (!statusState.loading && !statusState.emailVerified && !statusState.error) {
            fetchStatus();
        }
    }, []);

    return {
        ...statusState,
        fetchStatus
    };
};

export default useUserStatus; 