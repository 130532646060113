import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import CoffeeCardWeb from './CoffeeCardWeb';
import CoffeeCardContainer, { AddCoffeeForm } from './CoffeeCardContainer';
import { url } from '../../api';
import { ImageCacheProvider } from '../../context/ImageCacheContext';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faChevronDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FiPlus } from 'react-icons/fi';
import './coffeePage.css';

interface Tweet {
    tweetId: string;
    tweetUrl: string;
    datePosted: Date;
    tweetReplys?: Tweet[];
}

interface CoffeeRateInterface {
    _id: string;
    location: {
        storeCoordinates: { lat: number; lng: number };
        measurementCoordinates: { lat: number; lng: number };
        city: string;
        country: string;
    };
    coffeeType: string;
    coffeeShop: string;
    folderPath: string;
    imgName: string;
    overallRating: number;
    rating: { [key: string]: string };
    format: { [key: string]: string };
    rateColor: string;
    tweet?: Tweet;
    isSelfMade?: boolean;
    createdAt?: string; // using mongoose's built-in timestamp
    updatedAt?: string; // using mongoose's built-in timestamp
}

type SortOption = 'date' | 'rating' | 'style' | 'group-type';

interface CoffeeTypeGroup {
    title: string;
    types: string[];
}

const COFFEE_GROUPS: CoffeeTypeGroup[] = [
    {
        title: 'Hot Coffees',
        types: ['espresso', 'cappuccino', 'filter', 'latte']
    },
    {
        title: 'Cold Coffees',
        types: ['iced-latte', 'freddo-espresso', 'freddo-cappuccino']
    },
    {
        title: 'Greek Style',
        types: ['greek-coffee', 'frappe', 'frappe-sketo']
    }
];

const getCoffeeGroup = (coffeeType: string): string => {
    const normalizedType = coffeeType.toLowerCase().replace(/\s+/g, '-');
    for (const group of COFFEE_GROUPS) {
        if (group.types.some(type => normalizedType.includes(type))) {
            return group.title;
        }
    }
    return 'Other Coffees';
};

const ITEMS_PER_PAGE = 24;
const MOBILE_ITEMS_PER_PAGE = 12;
const PRELOAD_THRESHOLD = 0.5;

const CoffeeRatingsPage: React.FC = () => {
    const [rawCoffees, setRawCoffees] = useState<CoffeeRateInterface[]>([]);
    const [loading, setLoading] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [sortBy, setSortBy] = useState<SortOption>('date');
    const [isMobile, setIsMobile] = useState(false);
    const [visibleItems, setVisibleItems] = useState(ITEMS_PER_PAGE);
    const [showDeleteButtons, setShowDeleteButtons] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const loadMoreRef = useRef<HTMLDivElement>(null);
    const user = useSelector((state: RootState) => state.auth);

    // Detect mobile devices
    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth <= 768);
            // Update initial visible items based on device
            setVisibleItems(window.innerWidth <= 768 ? MOBILE_ITEMS_PER_PAGE : ITEMS_PER_PAGE);
        };

        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        return () => {
            window.removeEventListener('resize', checkIfMobile);
        };
    }, []);

    const refreshList = () => {
        setRefreshTrigger(prev => prev + 1);
    };

    const sortCoffees = (coffees: CoffeeRateInterface[], sortBy: SortOption) => {
        return [...coffees].sort((a, b) => {
            switch (sortBy) {
                case 'date':
                    return new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime();
                case 'rating':
                    return b.overallRating - a.overallRating;
                case 'style':
                    // First sort by group, then by type within group
                    const groupA = getCoffeeGroup(a.coffeeType);
                    const groupB = getCoffeeGroup(b.coffeeType);
                    if (groupA !== groupB) {
                        return groupA.localeCompare(groupB);
                    }
                    return a.coffeeType.localeCompare(b.coffeeType);
                case 'group-type':
                    return a.coffeeType.localeCompare(b.coffeeType);
                default:
                    return 0;
            }
        });
    };

    // Memoize the sorted coffees to prevent unnecessary re-sorting
    const sortedCoffees = useMemo(() => {
        return sortCoffees(rawCoffees, sortBy);
    }, [rawCoffees, sortBy]);

    useEffect(() => {
        const fetchCoffees = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${url}/api/coffee-rate/coffees`);
                const data = await response.json();
                setRawCoffees(data);
            } catch (error) {
                console.error('Error fetching coffees:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCoffees();
    }, [refreshTrigger]); // Only refetch when refresh is triggered

    // Set up intersection observer for infinite scroll
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !loading) {
                    // Load more items, with different batch sizes for mobile vs desktop
                    const batchSize = isMobile ? MOBILE_ITEMS_PER_PAGE * 2 : ITEMS_PER_PAGE * 2; // Double the batch size
                    setVisibleItems(prev => Math.min(prev + batchSize, sortedCoffees.length));
                }
            },
            {
                // Higher threshold means we start loading earlier
                threshold: PRELOAD_THRESHOLD,
                // Much larger root margin to start loading even earlier
                rootMargin: '1000px 0px'
            }
        );

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [loading, sortedCoffees.length, isMobile]);

    // Reset visible items when sort changes, but respect device type
    useEffect(() => {
        setVisibleItems(isMobile ? MOBILE_ITEMS_PER_PAGE : ITEMS_PER_PAGE);
    }, [sortBy, isMobile]);

    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortBy(event.target.value as SortOption);
    };

    const handleCoffeeDeleted = () => {
        refreshList();
    };

    // Handle opening the add coffee form
    const handleAddCoffee = () => {
        setShowAddForm(true);
    };

    // Handle closing the add coffee form
    const handleCloseForm = () => {
        setShowAddForm(false);
    };

    // Handle successful coffee addition
    const handleAddSuccess = () => {
        setShowAddForm(false);
        // Refresh the coffee list
        refreshList();
    };

    const renderGroupedCoffees = () => {
        // Group coffees by their group type
        const groupedCoffees = sortedCoffees.reduce((acc, coffee) => {
            const group = getCoffeeGroup(coffee.coffeeType);
            if (!acc[group]) {
                acc[group] = [];
            }
            acc[group].push(coffee);
            return acc;
        }, {} as Record<string, CoffeeRateInterface[]>);

        // Sort groups to maintain consistent order
        const orderedGroups = [
            ...COFFEE_GROUPS.map(g => g.title),
            'Other Coffees'
        ];

        return orderedGroups
            .filter(group => groupedCoffees[group]?.length > 0)
            .map(group => (
                <section key={group} className="coffee-type-section">
                    <div className="coffee-type-header">
                        <h2 className="coffee-type-title">
                            {group}
                            <span className="coffee-type-count">
                                ({groupedCoffees[group].length})
                            </span>
                        </h2>
                    </div>
                    <CoffeeCardContainer
                        coffeeData={groupedCoffees[group].slice(0, visibleItems)}
                        onDelete={handleCoffeeDeleted}
                        showDeleteButtons={showDeleteButtons}
                    />
                </section>
            ));
    };

    const renderGroupedByType = () => {
        // Group coffees by their exact type
        const groupedCoffees = sortedCoffees.reduce((acc, coffee) => {
            const type = coffee.coffeeType;
            if (!acc[type]) {
                acc[type] = [];
            }
            acc[type].push(coffee);
            return acc;
        }, {} as Record<string, CoffeeRateInterface[]>);

        // Format coffee type names for display
        const formatType = (type: string): string => {
            return type
                .split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        };

        // Sort by most common types
        return Object.keys(groupedCoffees)
            .sort((a, b) => groupedCoffees[b].length - groupedCoffees[a].length)
            .map(type => (
                <section key={type} className="coffee-type-section">
                    <div className="coffee-type-header">
                        <h2 className="coffee-type-title">
                            {formatType(type)}
                            <span className="coffee-type-count">
                                ({groupedCoffees[type].length})
                            </span>
                        </h2>
                    </div>
                    <CoffeeCardContainer
                        coffeeData={groupedCoffees[type].slice(0, visibleItems)}
                        onDelete={handleCoffeeDeleted}
                        showDeleteButtons={showDeleteButtons}
                    />
                </section>
            ));
    };

    return (
        <ImageCacheProvider>
            <div className="coffee-page">
                <div className="coffee-page-header">
                    <div className="page-header-left">
                        <h1 className="page-title">Coffee Ratings</h1>
                        {user.role === 'admin' && (
                            <>
                                <button
                                    className="admin-toggle-button"
                                    onClick={() => setShowDeleteButtons(!showDeleteButtons)}
                                    title={showDeleteButtons ? "Hide Delete Buttons" : "Show Delete Buttons"}
                                >
                                    <FontAwesomeIcon icon={showDeleteButtons ? faEyeSlash : faEye} />
                                </button>

                                <button
                                    className="add-coffee-header-button"
                                    onClick={handleAddCoffee}
                                    aria-label="Add Coffee"
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </>
                        )}
                    </div>
                    <div className="sort-controls">
                        <select
                            className="sort-select"
                            value={sortBy}
                            onChange={handleSortChange}
                        >
                            <option value="date">Sort by Date</option>
                            <option value="rating">Sort by Rating</option>
                            <option value="style">Group by Style</option>
                            <option value="group-type">Group by Type</option>
                        </select>
                        <FontAwesomeIcon icon={faChevronDown} className="sort-icon" />
                    </div>
                </div>

                {/* Add Coffee Form Modal */}
                {showAddForm && (
                    <AddCoffeeForm
                        onClose={handleCloseForm}
                        onSuccess={handleAddSuccess}
                    />
                )}

                {loading && visibleItems === ITEMS_PER_PAGE ? (
                    <div>Loading...</div>
                ) : sortBy === 'style' ? (
                    renderGroupedCoffees()
                ) : sortBy === 'group-type' ? (
                    renderGroupedByType()
                ) : (
                    <div className="coffee-grid">
                        {sortedCoffees.slice(0, visibleItems).map((coffee, index) => (
                            <CoffeeCardWeb
                                key={coffee._id || index}
                                coffeeData={coffee}
                                onDelete={handleCoffeeDeleted}
                                showDeleteButtons={showDeleteButtons}
                            />
                        ))}
                    </div>
                )}
                {visibleItems < sortedCoffees.length && (
                    <div ref={loadMoreRef} className="load-more-trigger">
                        Loading more...
                    </div>
                )}
            </div>
        </ImageCacheProvider>
    );
};

export default CoffeeRatingsPage;
