import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import logo from '../../assets/logos/nassos.svg';
import logo2 from '../../assets/logos/bolt.png';
import nassos_gour from '../../assets/photos/nassos_gour450.jpeg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { toast } from 'react-toastify'

import { signUp } from "../../store/actions/authActions";
import { logPageView } from '../../analytics/GoogleAnalytics';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from 'src/api';

const ErrorMessage = styled.p`
    color: #ac0000;
    font-size: 1rem;
    margin: 0 10px;
`;

// styled component for the checkbox container
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 10px;
  padding: 10px;
  background-color: #ffd8a1;
  border: 1px solid #e0a045;
  border-radius: 4px;
`;

// styled component for the checkbox itself
const StyledCheckbox = styled.input`
  margin-right: 10px;
  transform: scale(1.3);
  cursor: pointer;
`;

// styled component for the checkbox label
const CheckboxLabel = styled.label`
  font-size: 0.9rem;
  font-weight: 600;
`;

const SignupPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [logError, setLogError] = useState(null);
  const [showTermsCheckbox, setShowTermsCheckbox] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const { token, error } = useSelector(state => state.auth);

  // check if current date is before March 24, 2025
  const isBeforeDeadline = () => {
    const deadline = new Date('2025-03-22'); // year-month-day format
    const currentDate = new Date();
    return currentDate < deadline;
  };

  useEffect(() => {
    document.title = 'NΛSOS Sign Up'
    logPageView();

    // only check localStorage if we're before the deadline
    if (isBeforeDeadline()) {
      // check if we previously detected s4br in the username and saved it to localStorage
      const savedTermsRequired = localStorage.getItem('termsRequired');
      if (savedTermsRequired === 'true') {
        setShowTermsCheckbox(true);
      }
    } else {
      // if we're past the deadline, clear the localStorage item
      localStorage.removeItem('termsRequired');
    }
  }, []);

  useEffect(() => {
    if (error) {
      setLogError(error);
    }
  }, [error]);

  // state to hold input values
  const [user, setUser] = useState({
    email: '',
    username: '',
    password: ''
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const stateAuth = useSelector((state) => state.auth);
  // if(stateAuth._id) {
  //   return (<Navigate to="/"/>)
  // }

  // handle input change
  const handleChange = (event) => {
    event.preventDefault()
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
  }

  useEffect(() => {
    if (token) {
      // navigete to home
      navigate('/');
    }
  }, [token, navigate]);

  const goBack = () => {
    setLogError(null);
    navigate(-1); // Navigates back to the previous page
  };

  const handleUsernameChange = (event) => {
    event.preventDefault()
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
    let newUsername = event.target.value;
    newUsername = newUsername.toLowerCase().replace(/\s+/g, "");
    if (newUsername.length >= 2) {
      let firstChar = newUsername[0];
      let lastChar = newUsername[newUsername.length - 1];
      newUsername = firstChar + newUsername.slice(1, -1).replace(/a/gi, "4").replace(/s/gi, "5").replace(/o/gi, "0") + lastChar;
    }

    // check if the username includes 's4br' and we're before the deadline
    if (newUsername.includes('s4br') && !showTermsCheckbox && isBeforeDeadline()) {
      setShowTermsCheckbox(true);
      // store this in localStorage so it persists even if they change the username
      localStorage.setItem('termsRequired', 'true');
    }

    setUser({ ...user, username: newUsername });
  }

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  }

  const sendVerificationEmail = async () => {
    try {
      const response = await axios.post(url + '/api/verify/send-verification-code', {}, {
        headers: {
          'x-auth-token': localStorage.getItem('token'),
        },
      });

      if (response.status === 200) {
        return true;
      } else {
        console.error('Failed to send verification email');
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
    }
  };

  const handleSubmit = async (event) => {
    setLogError(null);
    event.preventDefault();

    // check if terms need to be accepted but aren't
    if (showTermsCheckbox && !termsAccepted) {
      setLogError("You must accept the terms to continue");
      return;
    }

    if (!user.email || user.email === "") {
      setUser({ ...user, email: "" });
    }
    try {
      dispatch(signUp(user));
      if (token) {
        navigate('/');
      }
    } catch (error) {
      // Handle errors
      // setError(error.message);
    }
  }
  // const navigate = useNavigate()

  return (
    <FullContainer>

      <SignupContainer>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        <BackButton onClick={goBack}>
          <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
        </BackButton>
        <H1Style>please signup</H1Style>
        <StyledForm onSubmit={handleSubmit}>
          email
          <br />
          <StyledInput type="email" name="email" placeholder='email' value={user.email} onChange={handleChange} />
          <br />
          username (450 formated)
          <br />
          <StyledInput type="username" name="username" placeholder='username' value={user.username} onChange={handleUsernameChange} required />
          <br />
          password
          <br />
          <StyledInput type={passwordVisible ? "text" : "password"} name="password" placeholder='password' value={user.password} onChange={handleChange} required />
          <i className="fa fa-eye" aria-hidden="true" onClick={() => setPasswordVisible(!passwordVisible)}></i>
          <br />

          {showTermsCheckbox && (
            <>
            <div className='m-2 text-orange-400'>
             <span className='[text-shadow:1px_1px_3px_rgba(0, 0, 0, 1)] '>
              sabry, you have delayed your sign up for significant time.<br/>
              </span>
              <span className='[text-shadow:1px_1px_3px_rgba(0, 0, 0, 1)] font-black'>
                you have to accept the term below to continue
              </span>
            </div>
            <CheckboxContainer>
              <StyledCheckbox
                type="checkbox"
                id="terms"
                checked={termsAccepted}
                onChange={handleTermsChange}
              />
              <CheckboxLabel htmlFor="terms"> i acknowledge that i owe nassos a bj <span className='font-black'><it>upon request</it></span></CheckboxLabel>
            </CheckboxContainer>
            </>
          )}

          <ErrorMessage>{logError}</ErrorMessage>

          <StyledSubmit type="submit" value="sign up" />
          <div style={{ fontSize: "0.9rem", fontWeight: "600", color: "#525252", margin: "4px" }}>already have an account? <Link to={"/login"}>login here</Link></div>
          <div style={{ fontSize: "0.9rem", fontWeight: "600", color: "#525252", margin: "4px" }}>forgot your password? <Link to={"/forgot-pw"}>reset password here</Link></div>

        </StyledForm>
      </SignupContainer>

      <ImageContainer>
        <StyledImg src={nassos_gour} className="image-nassos" alt="logo" />
      </ImageContainer>
    </FullContainer>

  );
}


const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 40px;
  /* justify-content: flex-end; */
  height: 100vh;
  background-color: #8be0f5;
  text-emphasis-color: #223126;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* padding-top: 100px; */
  text-align: right;
  /* justify-content: flex-end; */
  height: inherit;
  background-color: #8be0f5;
  text-emphasis-color: #223126;
`;

const StyledImg = styled.img`
  /* position: fixed; */
  /* top: 0;
  left: 0; */
  width: 50%;
  margin-top: 10px;
  margin-left: 20px;
  opacity: 0.3;
  /* align-self: center; */
  /* allign vertically */
  /* display: flex; */
  /* flex-direction: column; */
  justify-self: center;

  /* z-index: 9999; */
  /* position: absolute; */

`;

const BackButton = styled.div`
  display: block;
  /* padding: 8px 16px; */
  font-size: 20px;
  color: #000000;
  text-decoration: none;
  transition: font-size 0.1s;
  &:hover {
    color: #555;
    font-size: 22px;
  }
`;

const StyledForm = styled.form`
	/* border: 1px solid; */
	/* padding: 10px; */
	/* box-shadow: 5px 10px rgba(0, 0, 0, 0.7); */
	/* margin:  20px; */
	/* text-align: left; */
`;

const StyledInput = styled.input`
	::-webkit-input-placeholder {
    color: gray;
  }
  :-moz-placeholder {
    color: gray;
  }
  ::-moz-placeholder {
    color: gray;
  }
  :-ms-input-placeholder {
    color: gray;
  }
  zoom: 1;
  font-size: 1rem;
	border: 1px solid;
	padding: 5px;
  width: 45%;
  min-width: 10rem;
	box-shadow: inset 2px 2px 2px rgba(53, 53, 53, 0.401);
	margin:  10px;
  transition: 0.2s;
	&:hover {
    /* background-color: ${props => (props.active ? '#7edef1' : '#a1f4ff')}; */
    padding: 6px;
		/* box-shadow: 4px 4px 4px rgba(236, 236, 236, 1); */
  }
  @media (max-width: 600px)  {
    width: 90%;
  }

`;

const StyledSubmit = styled.input`
	border: 1px solid;
	padding: 5px;
	/* color: #18d3fd; */
	background-color:#66d6f5;
	box-shadow: 2px 2px 4px rgba(72, 72, 72, 0.344);
	margin:  10px;
	text-align: center;
  font-size: 0.8rem;
	/* background-color: ${props => (props.active ? '#9beece' : 'white')}; */
  transition: padding 0.2s;
  &:hover {
    background-color: ${props => (props.active ? '#38aadf' : '#47c2f6')};
    padding: 7px;
  }
  @media (max-width: 600px)  {
    height: 50px;
    width: 30%;
  }
`;

const FullContainer = styled.div`
	display: grid;
	grid-template-columns: 2fr 1fr;
	/* grid-column-gap: 10px; */
  @media (max-width: 600px)  {
    display: flex;
    flex-direction: column;
  }
`;

const BoxDiv = styled.div`
	border: 1px solid;
	padding: 10px;
	box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
	margin:  20px;
`;

const H1Style = styled.h1`
	float: right
`;

export default SignupPage;
