import React, { useState } from 'react';
import styled from 'styled-components';
import BasicButton from '../basics/BasicButton';
import { faCalendarAlt, faGift } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import useUserStatus from '../../store/hooks/useUserStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { setHeaders } from 'src/api';
import { url } from "../../api";
import { useSelector } from 'react-redux';

// styled components
const ExpandableButtonWrapper = styled.div<{ isExpanded: boolean }>`
  position: relative;
  border: 1.5px solid rgb(35, 35, 35);
  box-shadow: 3px 3px rgba(0, 0, 0, 0.6);
  border-radius: 1px;
  background-color: ${props => props.isExpanded ? '#e0effc' : '#a0d8f8'};
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  height: ${props => props.isExpanded ? 'auto' : '44px'};
  min-height: 44px;
  width: ${props => props.isExpanded ? 'fit-content' : '180px'};
  max-width: ${props => props.isExpanded ? '250px' : 'fit-content'};
  overflow: hidden;
  cursor: ${props => props.isExpanded ? 'default' : 'pointer'};

  &:hover {
    background-color: ${props => props.isExpanded ? '#abd7fe' : '#7bc5f5'};
    box-shadow: ${props => props.isExpanded ? '3px 3px rgba(0, 0, 0, 0.6)' : '0 2px 4px rgba(0, 0, 0, 0.1)'};
    transform: ${props => props.isExpanded ? 'none' : 'scale(0.98)'};
  }
`;

const ButtonContent = styled.div<{ isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  height: 44px;
  font-weight: 500;
  font-size: 1rem;
  color: rgb(35, 35, 35);
  opacity: ${props => props.isExpanded ? 0 : 1};
  transform: translateY(${props => props.isExpanded ? '-100%' : '0'});
  transition: opacity 0.2s ease, transform 0.3s ease;
`;

const ExpandedContent = styled.div<{ isExpanded: boolean }>`
  padding: 15px;
  opacity: ${props => props.isExpanded ? 1 : 0};
  max-height: ${props => props.isExpanded ? '300px' : '0'};
  transition: opacity 0.3s ease, max-height 0.4s ease;
  overflow: hidden;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledDateInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const DateDisplay = styled.div`
  font-size: 0.9rem;
  color: #555;
  margin-top: 2px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const BirthdayMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #ff4d8d;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  animation: bounce 1s infinite;
  padding: 10px 15px;
  background-color: #fff5f8;
  border: 1.5px solid #ff4d8d;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;

  @keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
  }

  a {
    color: #ff4d8d;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const PriorityMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 1rem;
  font-weight: 500;
  color: #ff4d8d;
  padding: 8px 12px;

  a {
    color: #ff4d8d;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const BirthdayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

interface BirthdayButtonProps {
    className?: string;
}

const BirthdayButton: React.FC<BirthdayButtonProps> = ({ className }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [displayDate, setDisplayDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isToday, setIsToday] = useState(false);
    const [isBirthday, setIsBirthday] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const { fetchStatus, birthdaySet } = useUserStatus();

    const birthdayDate = useSelector((state: any) => state.status.birthday);

    // check if birthday is today and update state
    React.useEffect(() => {
        // if birthdayDate is undefined, we haven't fetched the status yet
        if (birthdayDate === undefined) {
            return;
        }

        if (birthdayDate) {
            const isTodayBirthday = new Date(birthdayDate).toDateString() === new Date().toDateString();
            setIsToday(isTodayBirthday);
            setIsBirthday(true);
        } else {
            setIsToday(false);
            setIsBirthday(false);
        }
        setIsInitialized(true);
    }, [birthdayDate]);

    const handleButtonClick = () => {
        if (!isExpanded) {
            setIsExpanded(true);
        }
    };

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date = e.target.value;
        setSelectedDate(date);

        // format for display as dd/mm/yyyy
        if (date) {
            const [year, month, day] = date.split('-');
            setDisplayDate(`${day}/${month}/${year}`);
        } else {
            setDisplayDate('');
        }
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);
            // convert to ISO string for backend
            const birthDate = new Date(selectedDate).toISOString();

            const headers = setHeaders()
            if (!headers) {
                throw new Error('Authentication token not found');
            }

            await axios.post(url + '/api/stats/birthday',
                { birthDate },
                headers
            );

            // refresh status data
            fetchStatus();

            toast.success('Birthday set successfully!');
            setIsExpanded(false);
        } catch (error) {
            console.error('Error setting birthday:', error);
            toast.error('Failed to set birthday. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setSelectedDate('');
        setIsExpanded(false);
    };

    // don't show anything until we've initialized and confirmed there's no birthday
    if (!isInitialized || birthdayDate === undefined) {
        return null;
    }

    // if birthday is set, don't show the button
    if (birthdayDate) {
        return null;
    }

    if (isToday) {
        return (
            <BirthdayContainer>
                <BirthdayMessage>
                    <FontAwesomeIcon icon={faGift} className="pr-2" />
                    {"🎉 Happy Birthday! 🎉"}
                </BirthdayMessage>
                <span style={{ fontSize: '1rem', color: '#ff4d8d' }}
                    className='font-bold mx-4'>
                    {"🎁 You have priority for the AOTD! 4h50m more time than other users "}
                    <a href="/art-of-the-day" style={{ textDecoration: 'underline' }}> get it now!</a>
                </span>
            </BirthdayContainer>
        )
    }

    return (
        <ExpandableButtonWrapper
            className={className}
            isExpanded={isExpanded}
            onClick={handleButtonClick}
        >
            <ButtonContent isExpanded={isExpanded}>
                <FontAwesomeIcon icon={faCalendarAlt} className="pr-2" />
                {"Set Birthday"}
            </ButtonContent>

            <ExpandedContent isExpanded={isExpanded}>
                <DatePickerContainer>
                    <label htmlFor="birthday">Select your birthday:</label>
                    <StyledDateInput
                        id="birthday"
                        type="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                    />
                    <ActionButtons>
                        <BasicButton
                            text="Cancel"
                            bgColor="#f0f0f0"
                            hoverBgColor="#e0e0e0"
                            onClick={handleCancel}
                        />
                        <BasicButton
                            text="Save"
                            bgColor="#60fac1e1"
                            hoverBgColor="#1b8e64e1"
                            onClick={handleSave}
                            disabled={!selectedDate || isLoading}
                        />
                    </ActionButtons>
                    <span style={{ fontSize: '0.8rem', color: '#7f7f7f' }}
                        className='font-bold mx-1'>
                        {"having your birthday set, you will have many benefits when it's your birthday"}
                    </span>
                </DatePickerContainer>
            </ExpandedContent>
        </ExpandableButtonWrapper>
    );
};

export default BirthdayButton;
