import React, { createContext, useContext, useState } from 'react';

interface ImageCacheContextType {
    getImage: (id: string) => string | null;
    setImage: (id: string, url: string) => void;
    isLoading: (id: string) => boolean;
    setLoading: (id: string, loading: boolean) => void;
}

const ImageCacheContext = createContext<ImageCacheContextType>({
    getImage: () => null,
    setImage: () => { },
    isLoading: () => false,
    setLoading: () => { },
});

export const useImageCache = () => useContext(ImageCacheContext);

export const ImageCacheProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [imageCache, setImageCache] = useState<Record<string, string>>({});
    const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});

    const getImage = (id: string) => {
        return imageCache[id] || null;
    };

    const setImage = (id: string, url: string) => {
        setImageCache(prev => ({
            ...prev,
            [id]: url,
        }));
    };

    const isLoading = (id: string) => {
        return !!loadingState[id];
    };

    const setLoading = (id: string, loading: boolean) => {
        setLoadingState(prev => ({
            ...prev,
            [id]: loading,
        }));
    };

    return (
        <ImageCacheContext.Provider value={{ getImage, setImage, isLoading, setLoading }}>
            {children}
        </ImageCacheContext.Provider>
    );
}; 