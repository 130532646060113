import React, { useState, useEffect } from 'react';
import { uploadIcon, listIcons, fetchIcon, deleteIcon } from '../../utils/icons';
import { toast } from 'react-toastify';
import BasicButton from 'src/components/basics/BasicButton';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const IconsPage: React.FC = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [icons, setIcons] = useState<{ name: string; url: string }[]>([]);

    // Handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFile(event.target.files[0]);
        }
    };

    // Handle form submission
    const handleSubmit = async (event?: React.FormEvent) => {
        if (event) event.preventDefault();
        if (!selectedFile) {
            toast.error('Please select a file to upload');
            return;
        }

        try {
            const response = await uploadIcon(selectedFile);
            toast.success('Icon uploaded successfully');
            console.log('Upload response:', response);
            fetchIcons(); // Refresh the list after upload
            setSelectedFile(null); // Reset the selected file
        } catch (error) {
            console.error('Error uploading icon:', error);
            toast.error('Failed to upload icon');
        }
    };

    // Fetch icons
    const fetchIcons = async () => {
        try {
            const iconsList = await listIcons();
            const iconsWithUrls = await Promise.all(
                iconsList.map(async (iconName: string) => {
                    const url = await fetchIcon(iconName);
                    return { name: iconName, url };
                })
            );
            setIcons(iconsWithUrls);
        } catch (error) {
            console.error('Error fetching icons:', error);
            toast.error('Failed to fetch icons');
        }
    };

    // Handle icon deletion
    const handleDelete = async (iconName: string) => {
        try {
            await deleteIcon(iconName);
            toast.success('Icon deleted successfully');
            fetchIcons(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting icon:', error);
            toast.error('Failed to delete icon');
        }
    };

    useEffect(() => {
        fetchIcons();
    }, []);

    return (
        <div className="p-8 max-w-5xl mx-auto bg-gray-50 min-h-screen">
            <div className="p-4 mb-8">
                <h1 className="text-2xl font-semibold mb-6 text-center">Upload New Icon</h1>
                <div className="flex flex-col sm:flex-row items-center sm:space-x-4">
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="block w-full text-sm text-gray-700 border border-gray-300 rounded-md p-2"
                        />
                    </form>
                    <BasicButton
                        text="Upload Icon"
                        onClick={() => handleSubmit()}
                        bgColor="bg-blue-500"
                        hoverBgColor="hover:bg-blue-600"
                        className="mt-4 sm:mt-0 w-full sm:w-auto"
                    />
                </div>
            </div>
            <div className="p-2">
                <h2 className="text-2xl font-semibold mb-6 text-center">Existing Icons</h2>
                {icons.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 ">
                        {icons.map((icon, index) => (
                            <li key={index} className="flex flex-col items-center bg-gray-200 p-4 rounded-lg">
                                <img src={icon.url} alt={icon.name} className="w-16 h-16 mb-4" />
                                <span className="font-medium mb-2">{icon.name}</span>
                                <BasicButton
                                    text="Delete"
                                    onClick={() => handleDelete(icon.name)}
                                    bgColor="bg-red-500"
                                    hoverBgColor="hover:bg-red-600"
                                    textColor="text-white"
                                    className="mt-auto"
                                    icon={faTrash}
                                />
                            </li>
                        ))}
                    </div>
                ) : (
                    <p className="text-center text-gray-500">No icons available.</p>
                )}
            </div>
        </div>
    );
};

export default IconsPage;
