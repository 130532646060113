import axios from 'axios';
import { Dispatch } from 'redux';
import {url , setHeaders} from "../../api"

// action types
export const FETCH_USER_STATUS_REQUEST = 'FETCH_USER_STATUS_REQUEST';
export const FETCH_USER_STATUS_SUCCESS = 'FETCH_USER_STATUS_SUCCESS';
export const FETCH_USER_STATUS_FAILURE = 'FETCH_USER_STATUS_FAILURE';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';

// fetch user status
export const fetchUserStatus = () => async (dispatch: Dispatch) => {
    try {
        dispatch({ type: FETCH_USER_STATUS_REQUEST });

        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('Authentication token not found');
        }

        const headers = setHeaders()

        const response = await axios.get(url + '/api/stats/status',
            headers
        );

        dispatch({
            type: FETCH_USER_STATUS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        console.error('Error fetching user status:', error);
        dispatch({
            type: FETCH_USER_STATUS_FAILURE,
            payload: error instanceof Error ? error.message : 'Unknown error occurred'
        });
    }
}; 