import React, { useEffect } from "react";
import styled from 'styled-components';
import { logPageView } from "../../analytics/GoogleAnalytics";

const PrivacyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  padding: 40px;
  background-color: #f8f9fa;
`;

const HeaderTitle = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const BoxDiv = styled.div`
  border: 1px solid;
  padding: 20px;
  box-shadow: 5px 10px rgba(0, 0, 0, 0.7);
  margin: 20px 0;
  background-color: #ffffff;
`;

const SectionTitle = styled.h2`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const PrivacyPage = () => {
    useEffect(() => {
        document.title = `NΛSOS Privacy Policy`;
        logPageView();
    }, []);

    return (
        <PrivacyContainer>
            <HeaderTitle>
                Privacy Policy
            </HeaderTitle>

            <BoxDiv>
                <p>Last updated: {new Date().toLocaleDateString()}</p>
                <p>This Privacy Policy describes how NΛSOS collects, uses, and discloses your information when you use our website.</p>
            </BoxDiv>

            <BoxDiv>
                <SectionTitle>Information We Collect</SectionTitle>
                <p>We may collect personal information that you provide directly to us when you:</p>
                <ul>
                    <li>Register for an account</li>
                    <li>Use our interactive features</li>
                    <li>Make a purchase</li>
                    <li>Contact us</li>
                </ul>

                <SectionTitle>How We Use Your Information</SectionTitle>
                <p>We use the information we collect to:</p>
                <ul>
                    <li>Provide, maintain, and improve our services</li>
                    <li>Process transactions</li>
                    <li>Send you technical notices and support messages</li>
                    <li>Respond to your comments and questions</li>
                    <li>Analyze usage patterns of our services</li>
                </ul>

                <SectionTitle>Cookies</SectionTitle>
                <p>We use cookies and similar tracking technologies to track activity on our website and hold certain information. Cookies are files with a small amount of data which may include an anonymous unique identifier.</p>

                <SectionTitle>Data Security</SectionTitle>
                <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.</p>

                <SectionTitle>Changes to This Privacy Policy</SectionTitle>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>

                <SectionTitle>Contact Us</SectionTitle>
                <p>If you have any questions about this Privacy Policy, please contact us through the contact here: nassos@duck.com</p>
            </BoxDiv>
        </PrivacyContainer>
    );
};

export default PrivacyPage; 