import React, { useState } from 'react';
import CoffeeCardWeb from './CoffeeCardWeb';
import AddCoffeeForm from './AddCoffeeForm';
import './CoffeeCardWeb.css';

interface CoffeeCardContainerProps {
    coffeeData: any[];
    onDelete?: (id: string) => void;
    showDeleteButtons?: boolean;
}

const CoffeeCardContainer: React.FC<CoffeeCardContainerProps> = ({
    coffeeData,
    onDelete,
    showDeleteButtons = false
}) => {
    const [showAddForm, setShowAddForm] = useState(false);

    const handleCloseForm = () => {
        setShowAddForm(false);
    };

    const handleAddSuccess = () => {
        // Close the form and potentially refresh the data
        setShowAddForm(false);
        // You might want to reload the page or refresh the data here
        window.location.reload();
    };

    return (
        <>
            <div className="coffee-cards-grid">
                {coffeeData.map(coffee => (
                    <CoffeeCardWeb
                        key={coffee._id}
                        coffeeData={coffee}
                        onDelete={onDelete ? () => onDelete(coffee._id) : undefined}
                        showDeleteButtons={showDeleteButtons}
                    />
                ))}
            </div>

            {/* The Add Coffee button has been moved to the page title */}

            {showAddForm && (
                <AddCoffeeForm onClose={handleCloseForm} onSuccess={handleAddSuccess} />
            )}
        </>
    );
};

// Export both the component and the form trigger function
export default CoffeeCardContainer;
export { AddCoffeeForm }; 