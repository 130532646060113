import React, { useState } from "react";
import styles from "./stats-box.module.css";
import { useLoadStats } from "./useGetAllStats";
import styled from "styled-components";

interface Author {
  _id: string;
  userEmoji: string;
  postsCount: number;
}

interface Stats {
  aotdAuthors: Array<Author>;
}

const Title = styled.div`
  font-size: 1.1rem;
  color: #222;
  text-align: center;
  font-weight: 500;
  padding: 5px;
  padding-bottom: 2px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  /* z-index: 10; */
`;

const ShowMoreButton = styled.button`
  /* display: flex; */
  width: 200px;
  height: 30px;
  color: rgba(228, 228, 228, 0.952);
  border: 2px solid  rgba(228, 228, 228, 0.041);
  background-color: #2424247f;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  transition: transform 0.4s, box-shadow 0.4s;
  margin-top: -16px;
  /* overflow: hidden; */
  box-shadow: 4px 4px rgba(0, 0, 0, 0.156);
  /* z-index: 0; */
  &:hover {
    /* transform: translateY(3px); */
    transform: scale(0.98);
    box-shadow: 2px 2px rgba(0, 0, 0, 0.5);
    background-color: #3333338f;
  }
  &:active {
    transform: translateY(0);
    box-shadow: 3px 3px rgba(0, 0, 0, 0.3);
    background-color: #2a2a2ad9;
  }
`;

const YearSelector = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 10px;
`;

const YearDropdown = styled.div`
  position: absolute;
  right: 0;
  top: 25px;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
  min-width: 120px;
  border-radius: 4px;
`;

const YearOption = styled.div`
  padding: 10px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const YearDisplay = styled.span`
  cursor: pointer;
  position: relative;
  
  &:hover {
    text-decoration: underline;
  }
`;

const AotdAuthorsLeaderboard = () => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState<number | null>(currentYear);
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const [showAllAuthors, setShowAllAuthors] = useState(false);
  const { leaderboard } = useLoadStats(selectedYear);

  if (!leaderboard) {
    return <div>Loading...</div>;
  }

  const displayedAuthors = showAllAuthors
    ? leaderboard.aotdAuthors
    : leaderboard.aotdAuthors.sort((a, b) => b.postsCount - a.postsCount).slice(0, 5); // Display only the top 5 authors

  // generate years from 2023 to current year
  const years = Array.from({ length: currentYear - 2022 }, (_, i) => currentYear - i);

  return (
    <TableContainer>
      <Title>
        AOTD Authors Leaderboard
        <YearSelector>
          <YearDisplay onClick={() => setShowYearDropdown(!showYearDropdown)}>
            <span className="font-black"> {selectedYear || 'All Years'} </span>
            <i className={`fa fa-chevron-${showYearDropdown ? 'up' : 'down'}`} style={{
              fontSize: '0.8rem',
              marginBottom: '3px',
              verticalAlign: 'middle',
              display: 'inline-flex',
              alignItems: 'center'
            }}></i>
          </YearDisplay>
          {showYearDropdown && (
            <YearDropdown>
              <YearOption onClick={() => {
                setSelectedYear(null);
                setShowYearDropdown(false);
              }}>All Years</YearOption>
              {years.map(year => (
                <YearOption key={year} onClick={() => {
                  setSelectedYear(year);
                  setShowYearDropdown(false);
                }}>{year}</YearOption>
              ))}
            </YearDropdown>
          )}
        </YearSelector>
      </Title>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Author</th>
            <th>Posts</th>
          </tr>
        </thead>
        <tbody>
          {displayedAuthors
            .sort((a, b) => b.postsCount - a.postsCount) // Sort authors by post count in descending order
            .map((author, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{author.userEmoji ? `${author.userEmoji}.` : ''}{author._id}</td>
                <td>{author.postsCount}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
      <ShowMoreButton onClick={() => setShowAllAuthors(!showAllAuthors)}>
        {showAllAuthors ? <i className="fa fa-chevron-up"></i> : <i className="fa fa-chevron-down"></i>}
      </ShowMoreButton>
    </TableContainer>
  );
};

export default AotdAuthorsLeaderboard;
